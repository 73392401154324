/**** SIDEBAR  ****/
/**** TOPBAR ****/
/**** QUICKVIEW SIDEBAR ****/
/**** BOXED LAYOUT ****/
/**** MAIN CONTENT ****/
/**** WIDGETS ****/
/**** PAGES ****/
/**** SEARCH ****/
/**** BUILDER ****/
/******** UI **********/
/* Main Color */
/* Alert & Notification */
/* Popover */
/* Form */
/* Select Input */
/* Tabs */
/* Table */
/* Summernote */
/******* THEMES *******/
/* Mixin */
.col-xlg-1,
.col-xlg-2,
.col-xlg-3,
.col-xlg-4,
.col-xlg-5,
.col-xlg-6,
.col-xlg-7,
.col-xlg-8,
.col-xlg-9,
.col-xlg-10,
.col-xlg-11,
.col-xlg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}
@media (min-width: 1600px) {
  .col-xlg-1,
  .col-xlg-2,
  .col-xlg-3,
  .col-xlg-4,
  .col-xlg-5,
  .col-xlg-6,
  .col-xlg-7,
  .col-xlg-8,
  .col-xlg-9,
  .col-xlg-10,
  .col-xlg-11,
  .col-xlg-12 {
    float: left;
  }
  .col-xlg-12,
  .rtl .col-xlg-12 {
    width: 100%;
  }
  .col-xlg-11,
  .rtl .col-xlg-11 {
    width: 91.66666667%;
  }
  .col-xlg-10,
  .rtl .col-xlg-10 {
    width: 83.33333333%;
  }
  .col-xlg-9,
  .rtl .col-xlg-9 {
    width: 75%;
  }
  .col-xlg-8,
  .rtl .col-xlg-8 {
    width: 66.66666667%;
  }
  .col-xlg-7,
  .rtl .col-xlg-7 {
    width: 58.33333333%;
  }
  .col-xlg-6,
  .rtl .col-xlg-6 {
    width: 50%;
  }
  .col-xlg-5,
  .rtl .col-xlg-5 {
    width: 41.66666667%;
  }
  .col-xlg-4,
  .rtl .col-xlg-4 {
    width: 33.33333333%;
  }
  .col-xlg-3,
  .rtl .col-xlg-3 {
    width: 25%;
  }
  .col-xlg-2,
  .rtl .col-xlg-2 {
    width: 16.66666667%;
  }
  .col-xlg-1,
  .rtl .col-xlg-1 {
    width: 8.33333333%;
  }
  .col-xlg-pull-12 {
    right: 100%;
  }
  .col-xlg-pull-11 {
    right: 91.66666667%;
  }
  .col-xlg-pull-10 {
    right: 83.33333333%;
  }
  .col-xlg-pull-9 {
    right: 75%;
  }
  .col-xlg-pull-8 {
    right: 66.66666667%;
  }
  .col-xlg-pull-7 {
    right: 58.33333333%;
  }
  .col-xlg-pull-6 {
    right: 50%;
  }
  .col-xlg-pull-5 {
    right: 41.66666667%;
  }
  .col-xlg-pull-4 {
    right: 33.33333333%;
  }
  .col-xlg-pull-3 {
    right: 25%;
  }
  .col-xlg-pull-2 {
    right: 16.66666667%;
  }
  .col-xlg-pull-1 {
    right: 8.33333333%;
  }
  .col-xlg-pull-0 {
    right: auto;
  }
  .col-xlg-push-12 {
    left: 100%;
  }
  .col-xlg-push-11 {
    left: 91.66666667%;
  }
  .col-xlg-push-10 {
    left: 83.33333333%;
  }
  .col-xlg-push-9 {
    left: 75%;
  }
  .col-xlg-push-8 {
    left: 66.66666667%;
  }
  .col-xlg-push-7 {
    left: 58.33333333%;
  }
  .col-xlg-push-6 {
    left: 50%;
  }
  .col-xlg-push-5 {
    left: 41.66666667%;
  }
  .col-xlg-push-4 {
    left: 33.33333333%;
  }
  .col-xlg-push-3 {
    left: 25%;
  }
  .col-xlg-push-2 {
    left: 16.66666667%;
  }
  .col-xlg-push-1 {
    left: 8.33333333%;
  }
  .col-xlg-push-0 {
    left: auto;
  }
  .col-xlg-offset-12 {
    margin-left: 100%;
  }
  .col-xlg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-xlg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-xlg-offset-9 {
    margin-left: 75%;
  }
  .col-xlg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-xlg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-xlg-offset-6 {
    margin-left: 50%;
  }
  .col-xlg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-xlg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-xlg-offset-3 {
    margin-left: 25%;
  }
  .col-xlg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-xlg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-xlg-offset-0 {
    margin-left: 0;
  }
}
.m-auto {
  margin: auto;
}
.m-0 {
  margin: 0 !important;
}
.m-5 {
  margin: 5px !important;
}
.m-10 {
  margin: 10px !important;
}
.m-20 {
  margin: 20px !important;
}
.m-t-0 {
  margin-top: 0px !important;
}
.m-t-5 {
  margin-top: 5px !important;
}
.m-t-10 {
  margin-top: 10px !important;
}
.m-t-12 {
  margin-top: 12px !important;
}
.m-t-20 {
  margin-top: 20px !important;
}
.m-t-30 {
  margin-top: 30px !important;
}
.m-t-40 {
  margin-top: 40px !important;
}
.m-t-60 {
  margin-top: 60px !important;
}
.m-t-100 {
  margin-top: 100px !important;
}
.m-b-0 {
  margin-bottom: 0px !important;
}
.m-b-5 {
  margin-bottom: 5px !important;
}
.m-b-6 {
  margin-bottom: 6px !important;
}
.m-b-10 {
  margin-bottom: 10px !important;
}
.m-b-12 {
  margin-bottom: 12px !important;
}
.m-b-15 {
  margin-bottom: 15px !important;
}
.m-b-20 {
  margin-bottom: 20px !important;
}
.m-b-30 {
  margin-bottom: 30px !important;
}
.m-b-40 {
  margin-bottom: 40px !important;
}
.m-b-60 {
  margin-bottom: 60px !important;
}
.m-b-80 {
  margin-bottom: 80px !important;
}
.m-b-100 {
  margin-bottom: 100px !important;
}
.m-b-140 {
  margin-bottom: 140px !important;
}
.m-b-245 {
  margin-bottom: 245px !important;
}
.m-b-m30 {
  margin-bottom: -30px !important;
}
.m-b-m50 {
  margin-bottom: -50px !important;
}
.m-l-0 {
  margin-left: 0 !important;
}
.m-l-5 {
  margin-left: 5px !important;
}
.m-l-10 {
  margin-left: 10px !important;
}
.m-l-20 {
  margin-left: 20px !important;
}
.m-l-30 {
  margin-left: 30px !important;
}
.m-l-60 {
  margin-left: 60px !important;
}
.m-r-0 {
  margin-right: 0 !important;
}
.m-r-5 {
  margin-right: 5px !important;
}
.m-r-10 {
  margin-right: 10px !important;
}
.m-r-20 {
  margin-right: 20px !important;
}
.m-r-30 {
  margin-right: 30px !important;
}
.m-r-60 {
  margin-right: 60px !important;
}
.p-0 {
  padding: 0 !important;
}
.p-5 {
  padding: 5px !important;
}
.p-10 {
  padding: 10px !important;
}
.p-15 {
  padding: 15px !important;
}
.p-20 {
  padding: 20px !important;
}
.p-30 {
  padding: 30px !important;
}
.p-40 {
  padding: 40px !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-t-10 {
  padding-top: 10px !important;
}
.p-t-20 {
  padding-top: 20px !important;
}
.p-t-30 {
  padding-top: 30px !important;
}
.p-t-60 {
  padding-top: 60px !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-b-10 {
  padding-bottom: 10px !important;
}
.p-b-20 {
  padding-bottom: 20px !important;
}
.p-b-30 {
  padding-bottom: 30px !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-l-5 {
  padding-left: 5px !important;
}
.p-l-10 {
  padding-left: 10px !important;
}
.p-l-20 {
  padding-left: 20px !important;
}
.p-l-30 {
  padding-left: 30px !important;
}
.p-l-40 {
  padding-left: 40px !important;
}
.p-r-0 {
  padding-right: 0 !important;
}
.p-r-5 {
  padding-right: 5px !important;
}
.p-r-10 {
  padding-right: 10px !important;
}
.p-r-20 {
  padding-right: 20px !important;
}
.p-r-30 {
  padding-right: 30px !important;
}
.t-0 {
  top: 0;
}
.t-5 {
  top: 5px;
}
.t-10 {
  top: 10px;
}
.t-15 {
  top: 15px;
}
.b-0 {
  bottom: 0;
}
.b-5 {
  bottom: 5px;
}
.b-10 {
  bottom: 10px;
}
.b-15 {
  bottom: 15px;
}
.l-0 {
  left: 0;
}
.l-5 {
  left: 5px;
}
.l-10 {
  left: 10px;
}
.l-15 {
  left: 15px;
}
.r-0 {
  right: 0;
}
.r-5 {
  right: 5px;
}
.r-10 {
  right: 10px;
}
.r-15 {
  right: 15px;
}
.bd-0 {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}
.bd-3 {
  -moz-border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  border-radius: 3px !important;
}
.bd-6 {
  -moz-border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  border-radius: 6px !important;
}
.bd-9 {
  -moz-border-radius: 9px !important;
  -webkit-border-radius: 9px !important;
  border-radius: 9px !important;
}
.bd-full {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.bd-50p {
  -moz-border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.opacity-0 {
  opacity: 0;
}
.opacity-0 p {
  opacity: 0;
}
.opacity-20 {
  opacity: .2;
}
.opacity-20 p {
  opacity: .2;
}
.opacity-50 {
  opacity: .5;
}
.opacity-50 p {
  opacity: .5;
}
.opacity-70 {
  opacity: .7;
}
.opacity-70 p {
  opacity: .7;
}
.opacity-90 {
  opacity: .9;
}
.opacity-90 p {
  opacity: .9;
}
.width-0 {
  width: 0 !important;
}
.width-40 {
  width: 40px !important;
}
.width-300 {
  width: 300px !important;
}
.min-width-40 {
  min-width: 40px;
}
.width-16p {
  width: 16.6666% !important;
}
.width-20p {
  width: 20% !important;
}
.width-100p {
  width: 100% !important;
}
.h-0 {
  height: 0 !important;
  overflow: hidden !important;
}
.h-20 {
  height: 20px !important;
  overflow: hidden !important;
}
.h-30 {
  height: 30px !important;
  overflow: hidden !important;
}
.h-40 {
  height: 40px !important;
  overflow: hidden !important;
}
.h-50 {
  height: 50px !important;
  overflow: hidden !important;
}
.h-70 {
  height: 70px !important;
  overflow: hidden !important;
}
.h-90 {
  height: 90px !important;
  overflow: hidden !important;
}
.h-100 {
  height: 100px !important;
  overflow: hidden !important;
}
.h-150 {
  height: 150px !important;
  overflow: hidden !important;
}
.h-220 {
  height: 220px !important;
  overflow: hidden !important;
}
.h-250 {
  height: 250px !important;
  overflow: hidden !important;
}
.h-280 {
  height: 280px !important;
  overflow: hidden !important;
}
.h-300 {
  height: 300px !important;
  overflow: hidden !important;
}
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.dis-inline {
  display: inline;
}
.dis-inline-b {
  display: inline-block;
}
.dis-block {
  display: block !important;
}
.dis-none {
  display: none;
}
.f-left {
  float: left !important;
}
.f-right {
  float: right !important;
}
.no-border {
  border: none !important;
  box-shadow: none;
}
.no-bd {
  border: none !important;
  box-shadow: none;
}
.border {
  border: 1px solid #efefef !important;
}
.border-primary {
  border-color: #319DB5;
}
.border-gray {
  border: 1px solid #efefef;
}
.border-bottom {
  border-bottom: 1px solid #efefef !important;
}
.border-top {
  border-top: 1px solid #efefef !important;
}
.border-right {
  border-right: 1px solid #efefef !important;
}
.border-left {
  border-left: 1px solid #efefef !important;
}
.border-hor {
  border-bottom: 1px solid #efefef !important;
  border-top: 1px solid #efefef !important;
}
.border-ver {
  border-left: 1px solid #efefef !important;
  border-right: 1px solid #efefef !important;
}
.bd-white {
  border-color: #ffffff !important;
}
.bd-green {
  border-left: 3px solid #18a689;
}
.bd-red {
  border-left: 3px solid #c9625f;
}
.bd-blue {
  border-left: 3px solid #319DB5;
}
.bd-t-red {
  border-top: 4px solid #c9625f;
}
.bd-t-green {
  border-top: 4px solid #5FB675;
}
.bd-t-blue {
  border-top: 4px solid #0090D9;
}
.bd-t-dark {
  border-top: 4px solid #2B2E33;
}
.bd-t-purple {
  border-top: 4px solid #A58BD3;
}
.bd-l-red {
  border-left: 4px solid #c9625f;
}
.bd-l-green {
  border-left: 4px solid #18a689;
}
.bd-l-blue {
  border-left: 4px solid #1D86A9;
}
.bd-l-dark {
  border-left: 4px solid #2B2E33;
}
.bd-l-purple {
  border-left: 4px solid #A58BD3;
}
.bd-b-red {
  border-bottom: 4px solid #c9625f;
}
.bd-b-green {
  border-bottom: 4px solid #18a689;
}
.bd-b-blue {
  border-bottom: 4px solid #319DB5;
}
.bd-b-dark {
  border-bottom: 4px solid #2B2E33;
}
.bd-b-purple {
  border-bottom: 4px solid #A58BD3;
}
.img-sm {
  height: 35px;
  width: 35px;
}
.img-md {
  height: 65px;
  width: 65px;
}
.img-lg {
  height: 120px;
  width: 120px;
}
.bg-gray:not(i) {
  background-color: #82848A !important;
  color: #000000 !important;
}
i.bg-gray::before {
  background-color: #82848A !important;
  color: #000000 !important;
}
.bg-gray.form-control:focus {
  background-color: #82848A !important;
  color: #000000 !important;
}
.bg-gray-light:not(i) {
  background-color: #fafafa !important;
  color: #5B5B5B !important;
}
i.bg-gray-light::before {
  background-color: #fafafa !important;
  color: #5B5B5B !important;
}
.bg-gray-light.form-control:focus {
  background-color: #fafafa !important;
  color: #5B5B5B !important;
}
.modal-footer.bg-gray-light:not(i) {
  background-color: #EFEFEF !important;
  color: #5B5B5B !important;
}
.bg-red:not(i) {
  background-color: #C9625F !important;
  color: #fff !important;
}
i.bg-red::before {
  background-color: #C9625F !important;
  color: #fff !important;
}
.bg-red.form-control:focus {
  background-color: #C9625F !important;
  color: #fff !important;
}
.bg-yellow:not(i) {
  background-color: #EBC85E !important;
  color: #fff !important;
}
i.bg-yellow::before {
  background-color: #EBC85E !important;
  color: #fff !important;
}
.bg-yellow.form-control:focus {
  background-color: #EBC85E !important;
  color: #fff !important;
}
.bg-white:not(i) {
  background-color: #fff !important;
  color: black !important;
}
i.bg-white::before {
  background-color: #fff !important;
  color: black !important;
}
.bg-white.form-control:focus {
  background-color: #fff !important;
  color: black !important;
}
.bg-light:not(i) {
  background-color: #E9E9E9 !important;
  color: black !important;
}
i.bg-light::before {
  background-color: #E9E9E9 !important;
  color: black !important;
}
.bg-light.form-control:focus {
  background-color: #E9E9E9 !important;
  color: black !important;
}
.bg-green:not(i) {
  background-color: #18a689 !important;
  color: #fff !important;
}
i.bg-green::before {
  background-color: #18a689 !important;
  color: #fff !important;
}
.bg-green.form-control:focus {
  background-color: #18a689 !important;
  color: #fff !important;
}
.bg-blue:not(i) {
  background-color: #4584D1 !important;
  color: #fff !important;
}
i.bg-blue::before {
  background-color: #4584D1 !important;
  color: #fff !important;
}
.bg-blue.form-control:focus {
  background-color: #4584D1 !important;
  color: #fff !important;
}
.bg-blue2:not(i) {
  background-color: #3498db !important;
  color: #fff !important;
}
i.bg-blue2::before {
  background-color: #3498db !important;
  color: #fff !important;
}
.bg-blue2.form-control:focus {
  background-color: #3498db !important;
  color: #fff !important;
}
.bg-primary:not(i) {
  background-color: #319DB5 !important;
  color: #fff !important;
}
i.bg-primary::before {
  background-color: #319DB5 !important;
  color: #fff !important;
}
.bg-primary.form-control:focus {
  background-color: #319DB5 !important;
  color: #fff !important;
}
.background-primary:not(i) {
  background-color: #319DB5;
  color: #fff;
}
i.background-primary::before {
  background-color: #319DB5;
  color: #fff;
}
.background-primary.form-control:focus {
  background-color: #319DB5;
  color: #fff;
}
.bg-primary-dark:not(i) {
  background-color: #164954;
  color: #fff;
}
i.bg-primary-dark::before {
  background-color: #164954;
  color: #fff;
}
.bg-primary-dark.form-control:focus {
  background-color: #164954;
  color: #fff;
}
.bg-blue-light:not(i) {
  background-color: #e8f4f8 !important;
  color: #5B5B5B !important;
}
i.bg-blue-light::before {
  background-color: #e8f4f8 !important;
  color: #5B5B5B !important;
}
.bg-blue-light.form-control:focus {
  background-color: #e8f4f8 !important;
  color: #5B5B5B !important;
}
.bg-blue-dark:not(i) {
  background-color: #30364A !important;
  color: #fff !important;
}
i.bg-blue-dark::before {
  background-color: #30364A !important;
  color: #fff !important;
}
.bg-blue-dark.form-control:focus {
  background-color: #30364A !important;
  color: #fff !important;
}
.bg-orange:not(i) {
  background-color: #F2A057 !important;
  color: #fff !important;
}
i.bg-orange::before {
  background-color: #F2A057 !important;
  color: #fff !important;
}
.bg-orange.form-control:focus {
  background-color: #F2A057 !important;
  color: #fff !important;
}
.bg-purple:not(i) {
  background-color: #A58BD3 !important;
  color: #fff !important;
}
i.bg-purple::before {
  background-color: #A58BD3 !important;
  color: #fff !important;
}
.bg-purple.form-control:focus {
  background-color: #A58BD3 !important;
  color: #fff !important;
}
.bg-purple2:not(i) {
  background-color: #6E62B5 !important;
  color: #fff !important;
}
i.bg-purple2::before {
  background-color: #6E62B5 !important;
  color: #fff !important;
}
.bg-purple2.form-control:focus {
  background-color: #6E62B5 !important;
  color: #fff !important;
}
.bg-purple-dark:not(i) {
  background-color: #393F51 !important;
  color: #fff !important;
}
i.bg-purple-dark::before {
  background-color: #393F51 !important;
  color: #fff !important;
}
.bg-purple-dark.form-control:focus {
  background-color: #393F51 !important;
  color: #fff !important;
}
.bg-dark:not(i) {
  background-color: #2B2E33 !important;
  color: #fff !important;
}
i.bg-dark::before {
  background-color: #2B2E33 !important;
  color: #fff !important;
}
.bg-dark.form-control:focus {
  background-color: #2B2E33 !important;
  color: #fff !important;
}
.bg-pink:not(i) {
  background-color: #DC88E6 !important;
  color: #fff !important;
}
i.bg-pink::before {
  background-color: #DC88E6 !important;
  color: #fff !important;
}
.bg-pink.form-control:focus {
  background-color: #DC88E6 !important;
  color: #fff !important;
}
.bg-aero:not(i) {
  background-color: #B9D5DB !important;
  color: #2C2C2C !important;
}
i.bg-aero::before {
  background-color: #B9D5DB !important;
  color: #2C2C2C !important;
}
.bg-aero.form-control:focus {
  background-color: #B9D5DB !important;
  color: #2C2C2C !important;
}
.bg-purple-gradient {
  background: #bf9bdd;
  background: -moz-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -ms-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -o-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(27%, #bf9bdd), color-stop(100%, #9e52dd));
  background: -webkit-radial-gradient(center, ellipse cover, #bf9bdd 27%, #9e52dd 100%);
  background: radial-gradient(ellipse at center, #bf9bdd 27%, #9e52dd 100%);
  color: #fff;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bf9bdd', endColorstr='#9e52dd', GradientType=1);
}
.bg-opacity-20 {
  background-color: rgba(0, 0, 0, 0.2);
}
.bg-corporate {
  background-color: #31303A !important;
  color: #fff !important;
}
.bg-iris {
  background-color: #223439 !important;
  color: #fff !important;
}
i.online,
i.busy,
i.away,
i.offline,
i.turquoise {
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  float: left;
  height: 7px;
  margin-right: 13px;
  margin-right: 8px;
  margin-top: 6px;
  width: 7px;
}
i.online {
  background-color: #18a689;
}
i.turquoise {
  background-color: #52c1df;
}
i.away {
  background-color: #ff9900;
}
i.busy {
  background-color: #c75757;
}
i.offline {
  background-color: rgba(255, 255, 255, 0.2);
}
.jqstooltip {
  background-color: rgba(0, 0, 0, 0.6);
  background: #000000 transparent;
  border: 1px solid white;
  border: none;
  color: white;
  font: 15px arial, san serif;
  left: 0px;
  padding: 5px;
  position: absolute;
  text-align: left;
  top: 0px;
  visibility: hidden;
  white-space: nowrap;
  z-index: 10000;
}
.jqsfield {
  color: white;
  font: 10px arial, san serif;
  text-align: left;
}
.help-block {
  font-size: 12px;
  font-weight: 500;
}
.dropdown-menu.fullwidth {
  -moz-border-radius: 0 !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0! important;
}
address {
  padding-left: 15px;
}
@media (min-width: 1600px) {
  .hidden-lg {
    display: block!important;
  }
}
.btn {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  border: none;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 7px 24px 8px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  vertical-align: middle;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.btn i {
  font-size: 12px;
}
.btn:hover {
  color: #fff;
  outline: 0;
  outline: none;
}
.btn:focus {
  color: #fff;
  outline: none !important;
  outline: 0;
  outline: none;
}
.btn:active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  outline: none;
}
.btn:active focus {
  outline: none !important;
}
.btn:not(.btn-sm):not(.btn-hg):not(.btn-xs):not(.btn-lg):not(.bootstrap-touchspin-up):not(.bootstrap-touchspin-down) {
  height: 35px;
}
.btn.bootstrap-touchspin-up,
.btn.bootstrap-touchspin-down {
  padding: 8px 20px 8px 15px !important;
}
.btn.bootstrap-touchspin-up i,
.btn.bootstrap-touchspin-down i {
  padding-right: 0 !important;
  padding-left: 0 !important;
  left: auto !important;
}
.input-group-btn:not(.btn-sm):not(.btn-hg):not(.btn-xs):not(.btn-lg) > .btn:not(.btn-sm):not(.btn-hg):not(.btn-xs):not(.btn-lg) {
  height: 35px;
}
.btn.dropdown-toggle {
  margin-bottom: 0;
  padding: 7px 12px 8px 24px;
}
.rtl .btn.dropdown-toggle {
  padding: 7px 24px 8px 12px;
}
.pull-right .btn {
  margin-right: 0;
}
.pull-left .btn {
  margin-left: 0;
}
.btn.btn-sm {
  font-size: 11px;
  padding: 5px 12px !important;
}
.btn.btn-sm .icons-office-52:before {
  font-size: 8px;
}
.btn.btn-lg {
  font-size: 16px;
  padding: 11px 48px;
}
.btn.btn-lg i {
  font-size: 18px;
}
.btn.btn-lg:hover {
  color: white;
}
.main-content .page-content .panel .panel-content .btn:not(.btn-sm) i {
  padding-right: 8px;
}
.modal-footer .btn {
  margin-bottom: 0;
}
.btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  outline: none;
}
.btn.active:focus {
  outline: none !important;
}
.btn.disabled {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  color: #ffffff;
  filter: alpha(opacity=100);
  filter: alpha(opacity=70);
  opacity: .7;
  opacity: 1;
}
.btn[disabled] {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  color: #ffffff;
  filter: alpha(opacity=100);
  filter: alpha(opacity=70);
  opacity: .7;
  opacity: 1;
}
fieldset[disabled].btn {
  background-color: #bdc3c7;
  color: rgba(255, 255, 255, 0.75);
  color: #ffffff;
  filter: alpha(opacity=100);
  filter: alpha(opacity=70);
  opacity: .7;
  opacity: 1;
}
.btn-embossed {
  -webkit-box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -2px 0 rgba(0, 0, 0, 0.15);
}
.btn-embossed:active {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}
.btn-square {
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px;
}
.btn-embossed.active {
  -webkit-box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.15);
}
.btn-wide {
  min-width: 140px;
  padding-left: 30px;
  padding-right: 30px;
}
.btn-link {
  color: #16a085;
}
.btn-link:hover {
  background-color: transparent;
  color: #1abc9c;
  text-decoration: underline;
}
.btn-link:focus {
  background-color: transparent;
  color: #1abc9c;
  text-decoration: underline;
}
.btn-link[disabled]:hover,
fieldset[disabled].btn-link:hover,
.btn-link[disabled]:focus,
fieldset[disabled].btn-link:focus {
  color: #bdc3c7;
  text-decoration: none;
}
.btn.btn-hg {
  font-size: 22px;
  line-height: 1.227;
  padding: 13px 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-lg {
  font-size: 17px;
  line-height: 1.471;
  padding: 10px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-lg .caret {
  border-left-width: 6px;
  border-right-width: 6px;
  border-top-width: 8px;
}
.btn-sm {
  font-size: 13px;
  line-height: 1.385;
  margin-left: 10px;
  padding: 2px 10px !important;
  padding: 9px 13px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.btn-sm:first-child {
  margin-left: 0;
}
.btn-xs {
  font-size: 12px;
  line-height: 1.083;
  padding: 6px 9px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.btn-tip {
  font-size: 92%;
  font-weight: 300;
  padding-left: 10px;
}
.btn-block {
  white-space: normal;
}
.btn-block i {
  margin-top: .2em;
}
.dropup .btn-default .caret {
  border-bottom-color: #fff;
}
.dropup .caret {
  border-bottom-width: 4px;
}
.dropup .btn-lg .caret {
  border-bottom-width: 4px;
}
.dropup .btn-primary .caret,
.dropup .btn-success .caret,
.dropup .btn-warning .caret,
.dropup .btn-danger .caret,
.dropup .btn-info .caret {
  border-bottom-color: #fff;
}
.btn-group-xs > .btn {
  font-size: 12px;
  line-height: 1.083;
  padding: 6px 9px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.btn-group-sm > .btn {
  font-size: 13px;
  line-height: 1.385;
  padding: 9px 13px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.btn-group-lg > .btn {
  font-size: 17px;
  line-height: 1.471;
  padding: 10px 19px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-group-gh > .btn {
  font-size: 22px;
  line-height: 1.227;
  padding: 13px 20px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.btn-group > .btn + .btn {
  margin-left: 0;
}
.btn-group > .btn + .dropdown-toggle {
  border-left: 2px solid rgba(52, 73, 94, 0.15);
  padding-left: 12px;
  padding-right: 12px;
}
.btn-group > .btn + .dropdown-toggle .caret {
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 0;
}
.btn-group > .btn.btn-gh + .dropdown-toggle .caret {
  margin-left: 7px;
  margin-right: 7px;
}
.btn-group > .btn.btn-sm + .dropdown-toggle .caret {
  margin-left: 0;
  margin-right: 0;
}
.dropdown-toggle .caret {
  margin-left: 8px;
  margin-top: 0;
}
.btn-group > .btn {
  font-weight: 400;
}
.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-left: 1px solid rgba(12, 12, 12, 0.1) !important;
}
.btn-group > .btn:last-child :not(:first-child) {
  border-left: 1px solid rgba(12, 12, 12, 0.1) !important;
}
.btn-group > .dropdown-menu {
  font-weight: 400;
}
.btn-group > .popover {
  font-weight: 400;
}
.btn-group {
  margin-right: 10px;
}
.btn-group:focus .dropdown-toggle {
  -webkit-transition: .25s;
  outline: 0;
  transition: .25s;
}
.btn-group i {
  padding-right: 7px;
}
.btn-group .btn {
  margin-right: 0;
}
.btn:not(.btn-sm):not(.btn-hg):not(.btn-xs):not(.btn-lg):not(.bootstrap-touchspin-up):not(.bootstrap-touchspin-down) {
  height: 35px;
}
.btn-group button.btn-sm {
  height: 25px;
}
.btn-group button.btn-xs {
  height: 24px;
}
.btn-group button.btn-lg {
  height: 45px;
}
.btn-group button.btn-hg {
  height: 52px;
}
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.75);
}
.btn-group.open .dropdown-toggle.btn-default {
  color: #616C76;
}
.btn-toolbar .btn.active {
  color: #fff;
}
.btn-toolbar .btn > [class^="fui-"] {
  font-size: 16px;
  margin: 0 1px;
}
.navbar-fixed-bottom .dropdown .caret {
  border-bottom-width: 4px;
}
.buttons-page .btn {
  margin-bottom: 10px;
}
.btn-primary .caret,
.btn-success .caret,
.btn-warning .caret,
.btn-danger .caret,
.btn-info .caret {
  border-top-color: #fff;
}
.btn-default {
  background-color: #e0e6eb;
  color: #616c76;
}
.btn-default .caret {
  border-top-color: #616c76;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: #d0dae1;
  border-color: #d0dae1;
  color: #616c76;
}
.btn-default.active {
  background: #c1cdd7;
  border-color: #c1cdd7;
  color: #616c76;
}
.open .dropdown-toggle.btn-default {
  background: #a2b4c3;
  border-color: #a2b4c3;
  color: #616c76;
}
.open .dropdown-toggle.btn-primary {
  background: #1b5765;
  border-color: #1b5765;
}
.open .dropdown-toggle.btn-info {
  background: #7b00ff;
  border-color: #7b00ff;
}
.open .dropdown-toggle.btn-white {
  background: #cccccc;
  border-color: #cccccc;
}
.open .dropdown-toggle.btn-blue {
  background: #005673;
  border-color: #005673;
}
.open .dropdown-toggle.btn-danger {
  background: #8a2e2e;
  border-color: #8a2e2e;
}
.open .dropdown-toggle.btn-dark {
  background: #070708;
  border-color: #070708;
}
.open .dropdown-toggle.btn-success {
  background: #0b4d3f;
  border-color: #0b4d3f;
}
.open .dropdown-toggle.btn-warning {
  background: #bb5e00;
  border-color: #bb5e00;
}
.open .dropdown-toggle.btn-inverse {
  background: #10161c;
  border-color: #10161c;
}
.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled].btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled].btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled].btn-default:focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled].btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled].btn-default.active {
  background-color: #839baf;
  border-color: #839baf;
}
.btn-primary {
  background-color: #474949;
  color: #fff;
}
.btn-primary:active {
  background: #267a8d;
  border-color: #267a8d;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #474949;
  border-color: #474949;
  color: #fff;
}
.btn-primary.active {
  background: #267a8d;
  border-color: #267a8d;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled].btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled].btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled].btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled].btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled].btn-primary.active {
  background-color: #10353d;
  border-color: #10353d;
}
.btn-info {
  background-color: #b066ff;
  color: #fff;
}
.btn-info:active {
  background: #9633ff;
  border-color: #9633ff;
}
.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #a34cff;
  border-color: #a34cff;
  color: #fff;
}
.btn-info.active {
  background: #9633ff;
  border-color: #9633ff;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled].btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled].btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled].btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled].btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled].btn-info.active {
  background-color: #6300cc;
  border-color: #6300cc;
}
.btn-white {
  background-color: #ffffff;
  border: 1px solid #E0E0E0 !important;
  color: #373C40;
}
.btn-white:active {
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.btn-white.btn-transparent {
  padding: 6px 24px;
}
.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
  background-color: #f2f2f2;
  border-color: #d6d6d6;
  color: #333333;
}
.btn-white.active {
  background: #e6e6e6;
  border-color: #e6e6e6;
}
.btn-white.disabled,
.btn-white[disabled],
fieldset[disabled].btn-white,
.btn-white.disabled:hover,
.btn-white[disabled]:hover,
fieldset[disabled].btn-white:hover,
.btn-white.disabled:focus,
.btn-white[disabled]:focus,
fieldset[disabled].btn-white:focus,
.btn-white.disabled:active,
.btn-white[disabled]:active,
fieldset[disabled].btn-white:active,
.btn-white.disabled.active,
.btn-white[disabled].active,
fieldset[disabled].btn-white.active {
  background-color: #b3b3b3;
  border-color: #b3b3b3;
}
.btn-blue {
  background-color: #00a2d9;
  color: #fff;
}
.btn-blue:active {
  background: #007ca6;
  border-color: #007ca6;
}
.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.open .dropdown-toggle.btn-blue {
  background-color: #008fc0;
  border-color: #008fc0;
  color: #fff;
}
.btn-blue.active {
  background: #007ca6;
  border-color: #007ca6;
}
.btn-blue.disabled,
.btn-blue[disabled],
fieldset[disabled].btn-blue,
.btn-blue.disabled:hover,
.btn-blue[disabled]:hover,
fieldset[disabled].btn-blue:hover,
.btn-blue.disabled:focus,
.btn-blue[disabled]:focus,
fieldset[disabled].btn-blue:focus,
.btn-blue.disabled:active,
.btn-blue[disabled]:active,
fieldset[disabled].btn-blue:active,
.btn-blue.disabled.active,
.btn-blue[disabled].active,
fieldset[disabled].btn-blue.active {
  background-color: #003040;
  border-color: #003040;
}
.btn-danger {
  background-color: #ef4a23;
  color: #fff;
}
.btn-danger:active {
  background: #b03b3b;
  border-color: #b03b3b;
}
.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #c14444;
  border-color: #c14444;
  color: #fff;
}
.btn-danger.active {
  background: #b03b3b;
  border-color: #b03b3b;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled].btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled].btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled].btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled].btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled].btn-danger.active {
  background-color: #642121;
  border-color: #642121;
}
.btn-dark {
  background-color: #353940;
  color: #fff;
}
.btn-dark .caret {
  border-top-color: #DDE4EC;
}
.btn-dark:active {
  background: #1e2024;
  border-color: #1e2024;
}
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.open .dropdown-toggle.btn-dark {
  background-color: #292d32;
  border-color: #292d32;
  color: #fff !important;
}
.btn-dark.active {
  background: #1e2024;
  border-color: #1e2024;
}
.btn-dark.disabled,
.btn-dark[disabled],
fieldset[disabled].btn-dark,
.btn-dark.disabled:hover,
.btn-dark[disabled]:hover,
fieldset[disabled].btn-dark:hover,
.btn-dark.disabled:focus,
.btn-dark[disabled]:focus,
fieldset[disabled].btn-dark:focus,
.btn-dark.disabled:active,
.btn-dark[disabled]:active,
fieldset[disabled].btn-dark:active,
.btn-dark.disabled.active,
.btn-dark[disabled].active,
fieldset[disabled].btn-dark.active {
  background-color: #000000;
  border-color: #000000;
}
.btn-success {
  background-color: #18a689;
  color: #fff;
}
.btn-success:active {
  background: #127964;
  border-color: #127964;
}
.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #159077;
  border-color: #159077;
  color: #fff;
}
.btn-success.active {
  background: #127964;
  border-color: #127964;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled].btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled].btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled].btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled].btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled].btn-success.active {
  background-color: #05201b;
  border-color: #05201b;
}
.btn-warning {
  background-color: #ff9122;
  color: #fff;
}
.btn-warning:active {
  background: #ee7800;
  border-color: #ee7800;
}
.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #ff8408;
  border-color: #ff8408;
  color: #fff;
}
.btn-warning.active {
  background: #ee7800;
  border-color: #ee7800;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled].btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled].btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled].btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled].btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled].btn-warning.active {
  background-color: #884400;
  border-color: #884400;
}
.btn-inverse {
  background-color: #34495e;
  color: #fff;
}
.btn-inverse:active {
  background: #222f3d;
  border-color: #222f3d;
}
.btn-inverse:hover,
.btn-inverse:focus,
.btn-inverse:active,
.btn-inverse.active,
.open .dropdown-toggle.btn-inverse {
  background-color: #2b3c4e;
  border-color: #2b3c4e;
  color: #fff;
}
.btn-inverse.active {
  background: #222f3d;
  border-color: #222f3d;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled].btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled].btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled].btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled].btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled].btn-inverse.active {
  background-color: #000000;
  border-color: #000000;
}
.btn.btn-transparent {
  background-color: transparent;
  margin-left: 0;
  margin-right: 20px;
}
.btn.btn-transparent:hover {
  color: #464646;
}
.btn.btn-sm.btn-transparent {
  background: none;
  color: #696969;
}
.btn.btn-fb {
  background-color: #3a548b;
  color: #fff;
}
.btn.btn-fb:hover {
  background-color: #364F83;
  color: #fff;
}
.btn-default.btn-transparent {
  border: 1px solid #D3D7DB !important;
  color: #333333;
}
.btn-default.btn-transparent:hover {
  background-color: rgba(197, 202, 208, 0.2);
  border: 1px solid #c5cad0;
  color: #333333;
}
.btn-primary.btn-transparent {
  border: 1px solid #2c8ca1 !important;
  color: #2c8ca1;
  background-color: transparent !important;
}
.btn-primary.btn-transparent:hover {
  background-color: rgba(37, 140, 209, 0.1) !important;
  border: 1px solid #258cd1;
  color: #258cd1;
}
.btn-info.btn-transparent {
  border: 1px solid #b066ff !important;
  color: #b066ff;
}
.btn-info.btn-transparent:hover {
  background-color: rgba(163, 76, 255, 0.1);
  border: 1px solid #46b8da;
  color: #a34cff;
}
.btn-warning.btn-transparent {
  border: 1px solid #ff8408 !important;
  color: #ff8408;
}
.btn-warning.btn-transparent:hover {
  background-color: rgba(238, 162, 54, 0.1);
  border: 1px solid #eea236;
  color: #eea236;
}
.btn-danger.btn-transparent {
  border: 1px solid #c14444 !important;
  color: #c14444;
}
.btn-danger.btn-transparent:hover {
  background-color: rgba(212, 63, 58, 0.1);
  border: 1px solid #d43f3a;
  color: #d43f3a;
}
.btn-success.btn-transparent {
  border: 1px solid #159077 !important;
  color: #159077;
}
.btn-success.btn-transparent:hover {
  background-color: rgba(76, 174, 76, 0.1);
  border: 1px solid #4cae4c;
  color: #4cae4c;
}
.btn-dark.btn-transparent {
  border: 1px solid #292d32 !important;
  color: #292d32 !important;
}
.btn-dark.btn-transparent:hover {
  background-color: rgba(31, 34, 37, 0.1);
  border: 1px solid #1f2225;
  color: #1f2225;
}
.btn-translucent {
  background: rgba(0, 0, 0, 0.2);
  color: #fff !important;
}
.btn-translucent:hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff !important;
}
.btn-translucent i {
  padding-right: 8px;
}
.btn.btn-rounded {
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.btn.btn-square {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.btn-group > .dropdown-toggle:not(:first-child) {
  border-left: 1px solid rgba(12, 12, 12, 0.1) !important;
}
.btn-group-vertical {
  margin-right: 20px;
}
.btn-group-vertical .btn {
  margin-bottom: 0;
}
.btn-group-vertical .btn i {
  padding-right: 0 !important;
}
.btn-icon {
  height: 35px;
  line-height: normal;
  padding: 7px 11px;
  width: 35px;
}
.btn-icon i {
  width: 11px;
}
.alert {
  font-family: 'Open Sans';
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.alert .icon-circle {
  background-color: rgba(0, 0, 0, 0.05);
  font-size: 25px;
  padding: 10px;
  display: inline-block;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.alert .media-body p {
  margin-bottom: 0;
  white-space: normal;
}
.alert a {
  color: #3C3C3C;
  font-weight: 600;
}
.alert button {
  margin-bottom: 0;
}
.alert .close .icons-office-52 {
  font-size: 10px;
}
.alert .media-left img {
  border: 3px solid rgba(0, 0, 0, 0.2);
  margin-top: -3px;
  width: 62px;
}
.alert > p + p {
  margin-top: 0;
}
h4.alert-title {
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}
.alert-success {
  background-color: #d1ede8;
  border-color: rgba(0, 0, 0, 0);
  color: #0D2213;
}
.alert-info {
  background-color: #deedf2;
  border-color: rgba(0, 0, 0, 0);
  color: #356A75;
}
.alert.alert-info a {
  color: #247699;
}
.alert-warning {
  background-color: #f3e6d1;
  border-color: rgba(0, 0, 0, 0);
  color: #3C2206;
}
.alert-danger {
  background-color: #fee0de;
  border-color: rgba(0, 0, 0, 0);
  color: #4E120C;
}
.alert-dark {
  background-color: #33363c;
  border-color: rgba(0, 0, 0, 0);
  border-radius: 4px;
  color: #E2E2E2;
}
.alert-dark h4.alert-title {
  color: #ffffff;
}
.alert-dark.alert a {
  color: #5DBFD5;
}
.alert-dark.alert .media-left img {
  border: 3px solid rgba(255, 255, 255, 0.2);
}
.alert.alert-dark .icon-circle {
  background-color: rgba(255, 255, 255, 0.1);
}
.alert.alert-dark .media-body p {
  height: auto;
  line-height: 20px;
  margin-bottom: 0;
  overflow: visible;
}
.alert.bg-blue,
.alert.bg-green,
.alert.bg-purple,
.alert.bg-gray-light,
.alert.bg-gray,
.alert.bg-white,
.alert.bg-red {
  border-radius: 2px;
}
.panel-content .alert.media {
  padding-bottom: 10px;
}
.panel-content .alert {
  border: none;
}
.panel-notif .panel-content {
  overflow: hidden;
  position: relative;
}
.noty_message {
  border: none !important;
  box-shadow: none !important;
  padding: 0 !important;
}
.noty_message .alert {
  border: none;
  margin-bottom: 10px;
  z-index: 3;
}
#noty_top_layout_container li:first-child {
  margin-top: 50px!important;
}
#noty_bottom_layout_container li {
  height: auto !important;
}
#noty_bottom_layout_container li .alert {
  margin-bottom: 0;
  margin-top: 10px;
}
#noty_topLeft_layout_container li:first-child {
  margin-top: 50px !important;
}
#noty_topCenter_layout_container li:first-child {
  margin-top: 50px !important;
}
#noty_topRight_layout_container li:first-child {
  margin-top: 50px !important;
}
.noty_inline_layout_container li,
#noty_topRight_layout_container li,
#noty_topLeft_layout_container li,
#noty_topCenter_layout_container li,
#noty_center_layout_container li,
#noty_centerLeft_layout_container li,
#noty_centerRight_layout_container li,
#noty_bottomLeft_layout_container li,
#noty_bottomCenter_layout_container li,
#noty_bottomRight_layout_container li {
  -moz-transition-delay: 0;
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
}
.noty_inline_layout_container {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.noty_inline_layout_container li {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.noty_inline_layout_container li:first-child {
  margin-bottom: 0;
}
.notif_container {
  height: 250px;
}
.notification_position {
  border: 2px dashed #DFE5E9;
  height: 250px;
  position: relative;
  width: 100%;
}
.notification_position .bit {
  background-color: #DFE5E9;
  cursor: pointer;
  position: absolute;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.notification_position .bit:hover {
  background-color: #d0d9df;
}
.notification_position .bit.active {
  background-color: #2B2E33;
}
.notification_position .bit.top {
  height: 22%;
  margin: 0 30%;
  top: 0;
  width: 40%;
}
.notification_position .bit.bottom {
  bottom: 0;
  height: 22%;
  margin: 0 30%;
  width: 40%;
}
.notification_position .bit.medium {
  height: 21%;
  margin: 0 30%;
  top: 40%;
  width: 40%;
}
.notification_position .bit.right {
  height: 22%;
  margin-left: 0;
  margin-right: 0;
  right: 0;
  width: 20%;
}
.notification_position .bit.left {
  height: 22%;
  left: 0;
  margin-left: 0;
  margin-right: 0;
  width: 20%;
}
.notification_position.disabled {
  background-color: #F3F6FA;
  opacity: 0.4;
}
.notification_position.disabled .bit {
  cursor: not-allowed;
}
.notif_pos_top {
  display: none;
}
.notif_pos_all {
  display: none;
}
.notif_pos_top.notification_position .bit.left {
  width: 100%;
}
.notif_pos_top_bottom.notification_position .bit.right {
  width: 100%;
}
.notif_pos_top_bottom.notification_position .bit.left {
  width: 100%;
}
.rtl #noty_bottomLeft_layout_container {
  left: 60px !important;
}
.badge {
  border-radius: 2px;
  font-weight: 300;
}
.badge-dark {
  background-color: #353940;
}
.badge-white {
  background-color: #ffffff;
  color: #2B2E33;
}
.badge-default {
  background-color: #e0e6eb;
}
.badge-primary {
  background-color: #319db5;
}
.badge-success {
  background-color: #18a689;
}
.badge-info {
  background-color: #b066ff;
}
.badge-warning {
  background-color: #ff9122;
}
.badge-danger {
  background-color: #c75757;
}
.badge-rounded {
  border: 2px solid rgba(255, 255, 255, 0.5);
  height: 24px;
  padding: 0;
  text-align: center;
  width: 24px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
}
.badge-sold {
  text-transform: uppercase;
}
.badge-stock {
  text-decoration: line-through;
}
.label {
  font-weight: 500;
}
.label-default {
  background-color: #e0e6eb;
  color: #121212;
}
.label-default[href]:hover {
  background-color: #d0dae1;
}
.label-default[href]:focus {
  background-color: #d0dae1;
}
.label-primary {
  background-color: #319db5;
}
.label-primary:hover {
  background-color: #2c8ca1;
}
.label-primary[href]:hover {
  background-color: #319db5;
}
.label-primary[href]:focus {
  background-color: #2c8ca1;
}
.label-success {
  background-color: #18a689;
}
.label-success:hover {
  background-color: #159077;
}
.label-success[href]:hover {
  background-color: #159077;
}
.label-success[href]:focus {
  background-color: #159077;
}
.label-info {
  background-color: #b066ff;
}
.label-info:hover {
  background-color: #a34cff;
}
.label-info[href]:hover {
  background-color: #a34cff;
}
.label-info[href]:focus {
  background-color: #a34cff;
}
.label-warning {
  background-color: #ff9122;
}
.label-warning:hover {
  background-color: #ff8408;
}
.label-warning[href]:hover {
  background-color: #ff8408;
}
.label-warning[href]:focus {
  background-color: #ff8408;
}
.label-danger {
  background-color: #c75757;
}
.label-danger:hover {
  background-color: #c14444;
}
.label-danger[href]:hover {
  background-color: #c14444;
}
.label-danger[href]:focus {
  background-color: #c14444;
}
.label-blue {
  background-color: #00a2d9;
}
.label-blue:hover {
  background-color: #008fc0;
}
.label-blue[href]:hover {
  background-color: #008fc0;
}
.label-blue[href]:focus {
  background-color: #008fc0;
}
.popover-dark {
  background-color: #2b2e33;
  border: 1px solid #202226;
}
.popover-dark .popover-title {
  background-color: #202226;
  border-bottom: 1px solid #202226;
  border: none;
  color: #ffffff;
}
.popover-dark .popover-content {
  background-color: #2b2e33;
  color: #ffffff;
  padding: 9px 14px;
}
.popover-dark.top .arrow:after {
  border-top-color: #2b2e33;
}
.popover-dark.bottom .arrow:after {
  border-bottom-color: #2b2e33;
}
.popover-dark.left .arrow:after {
  border-left-color: #2b2e33;
}
.popover-dark.right .arrow:after {
  border-right-color: #2b2e33;
}
.progress {
  -webkit-box-shadow: none;
  background: #ebedef;
  border-radius: 32px;
  box-shadow: none;
  height: 12px;
}
.progress-bar {
  -webkit-box-shadow: none;
  background: #319db5;
  box-shadow: none;
  line-height: 12px;
}
.progress-bar-success {
  background-color: #18a689;
}
.progress-bar-warning {
  background-color: #ff9122;
}
.progress-bar-danger {
  background-color: #c75757;
}
.progress-bar-info {
  background-color: #b066ff;
}
.progress-bar-purple {
  background-color: #b381f5;
}
.progress.progress-bar-thin {
  height: 5px;
}
.progress.progress-bar-large {
  height: 20px;
}
.progress.progress-bar-large .progress-bar {
  line-height: 20px;
}
.modal-open .modal {
  z-index: 2000;
}
.modal-full {
  width: 98%;
}
.modal-footer.text-center {
  text-align: center;
}
.modal-panel .btn {
  margin-bottom: 10px;
}
ul.list-unstyled {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nv-axisMaxMin {
  color: red !important;
}
.modal-content {
  border-radius: 0px;
  border: none;
}
.modal-dialog {
  margin-top: 10%;
  z-index: 10000;
}
.modal-header {
  border-bottom: none;
  text-transform: uppercase;
}
.modal-header .modal-title {
  font-size: 15px;
}
.modal-header .close {
  opacity: .3;
  text-shadow: none;
}
.modal-header i {
  font-size: 12px;
}
.modal-header[class*="bg-"].close {
  color: #ffffff;
  opacity: .4;
}
.modal-footer {
  border-top: none;
}
.modal h2 {
  font-size: 20px;
}
.modal-topfull {
  margin-top: 0;
  width: 100%;
}
.modal-topfull .modal-content {
  border-left: none;
  border-right: none;
  border-top: none;
}
.modal.modal-topfull {
  padding-right: 0 !important;
}
.modal-bottomfull .modal-content {
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.modal-bottomfull .modal-dialog {
  bottom: 0;
  left: 0;
  margin-bottom: 0;
  margin-top: auto;
  position: absolute;
  right: 0;
  width: 100%;
}
.modal.fade.in.modal-bottomfull .modal-dialog {
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-out;
}
.modal.fade.modal-bottomfull .modal-dialog {
  -o-transform: translate3d(0, 25%, 0);
  -webkit-transform: translate3d(0, 25%, 0);
  transform: translate3d(0, 25%, 0);
  transition: transform 0.3s ease-out;
}
.modal-slideright .modal-dialog {
  bottom: 0;
  height: 100%;
  left: auto;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 300px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.modal-slideright .modal-dialog .modal-content {
  border: none;
  height: 100%;
}
.modal-slideright .modal-dialog .modal-body {
  border-collapse: collapse;
  display: table;
  height: 100% !important;
  padding-left: 0px;
  padding-right: 0px;
  table-layout: fixed;
  vertical-align: middle;
  width: 100%;
}
.modal-slideright .modal-dialog .modal-body .row {
  display: table-row;
}
.modal-slideright .modal-dialog .modal-body .row .col-md-12 {
  display: table-cell;
  float: none;
  text-align: center;
  top: -20%;
  vertical-align: middle;
}
.modal-slideleft .modal-dialog {
  bottom: 0;
  height: 100%;
  left: 0;
  margin-bottom: 0;
  margin-top: 0;
  max-width: 300px;
  position: absolute;
  right: auto;
  top: 0;
  width: 100%;
}
.modal-slideleft .modal-dialog .modal-content {
  border: none;
  height: 100%;
}
.modal-slideleft .modal-dialog .modal-body {
  border-collapse: collapse;
  display: table;
  height: 100% !important;
  padding-left: 0px;
  padding-right: 0px;
  table-layout: fixed;
  vertical-align: middle;
  width: 100%;
}
.modal-slideleft .modal-dialog .modal-body .row {
  display: table-row;
}
.modal-slideleft .modal-dialog .modal-body .row .col-md-12 {
  display: table-cell;
  float: none;
  text-align: center;
  top: -20%;
  vertical-align: middle;
}
.modal.fade.modal-slideright .modal-dialog {
  -o-transform: translate3d(25%, 0, 0);
  -webkit-transform: translate3d(25%, 0, 0);
  transform: translate3d(25%, 0, 0);
  transition: transform 0.3s ease-out;
}
.modal.fade.modal-slideleft .modal-dialog {
  -o-transform: translate3d(-25%, 0, 0);
  -webkit-transform: translate3d(-25%, 0, 0);
  transform: translate3d(-25%, 0, 0);
  transition: transform 0.3s ease-out;
}
.modal.fade.in.modal-slideright .modal-dialog {
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-out;
}
.modal.fade.in.modal-slideleft .modal-dialog {
  -o-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease-out;
}
.modal-image .modal-content {
  border: none;
}
.modal-image .modal-content:before {
  background-image: url('../images/gradient-big.png');
  background-repeat: repeat-x;
  bottom: 0;
  content: " ";
  height: 70px;
  left: 0;
  position: absolute;
  right: 0;
  z-index: 1;
}
.modal-image .modal-header {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.modal-image .modal-body {
  padding: 0;
}
.modal-image .modal-footer {
  bottom: 0;
  color: #E9E9E9;
  font-size: 16px;
  left: 0;
  padding-bottom: 0;
  position: absolute;
  text-align: right;
  width: 100%;
  z-index: 2;
}
.modal-image .modal-footer p {
  font-family: 'Lato', arial;
  font-weight: 100;
  font-size: 16px;
}
.modal-img {
  -moz-transition: all 0.2s ease-out 0;
  -webkit-transition: all 0.2s ease-out 0;
  border: none;
  cursor: pointer;
  display: inline-block;
  transition: all 0.2s ease-out 0;
  width: 100%;
}
.modal-container {
  -moz-transition: all 0.2s ease-out 0;
  -webkit-transition: all 0.2s ease-out 0;
  background: #E6E6E6;
  cursor: pointer;
  height: 250px;
  padding-top: 50px;
  position: relative;
  text-align: center;
  transition: all 0.2s ease-out 0;
}
.modal-container:hover {
  background: #3C3C3C;
}
.basic-modal-img {
  width: 60%;
}
.large-modal-img {
  width: 60%;
}
.full-modal-img {
  width: 95%;
}
.top-modal-img {
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.bottom-modal-img {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  width: 100%;
}
.left-modal-img {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: auto;
}
.right-modal-img {
  bottom: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}
@media (min-width: 1600px) {
  .basic-modal-img {
    width: 40%;
  }
}
.form-control {
  -webkit-box-shadow: none !important;
  background-color: #ECEDEE;
  border: 1px solid #ECEDEE;
  box-shadow: none !important;
  color: #555555;
  display: inline-block;
  font-size: 13px;
  height: auto;
  line-height: normal;
  padding: 9px 12px 8px;
  vertical-align: middle;
  width: 100%;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}
.form-control::-webkit-input-placeholder {
  color: #B5B5B5;
}
.form-control:-moz-placeholder {
  color: #B5B5B5;
}
.form-control::-moz-placeholder {
  color: #B5B5B5;
}
.form-control:-ms-input-placeholder {
  color: #B5B5B5;
}
.form-control:focus {
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #ffffff !important;
  background-color: #e9ebef !important;
  border-color: #A0BDDA;
  border: 1px solid #CFD2D6;
  box-shadow: none;
  outline: none;
  outline: none !important;
}
.form-control[class*="bg-"] {
  color: #121212 !important;
}
.form-control[class*="bg-"]::-webkit-input-placeholder {
  color: #EEEEEE;
}
.form-control[class*="bg-"]:-moz-placeholder {
  color: #EEEEEE;
}
.form-control[class*="bg-"]::-moz-placeholder {
  color: #EEEEEE;
}
.form-control[class*="bg-"]:-ms-input-placeholder {
  color: #EEEEEE;
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.form-control.form-white {
  background-color: #ffffff;
  border: 1px solid #ECEDEE;
  color: #555555;
}
.form-control.form-white:hover {
  background-color: #ffffff !important;
  border: 1px solid #C5C8CB;
  color: #555555;
  outline: none;
}
.form-control.form-white:focus {
  background-color: #ffffff !important;
  border: 1px solid #C5C8CB;
  color: #555555;
  outline: none;
}
.form-control.form-white:visited {
  background-color: #ffffff !important;
  border: 1px solid #C5C8CB;
  color: #555555;
  outline: none;
}
.form-white ~ .bootstrap-tagsinput {
  background-color: #ffffff !important;
  border: 1px solid #ECEDEE !important;
}
.form-control.form-dark {
  background-color: #272727;
  border: 1px solid #2F3133;
  color: #E0E0E0;
}
.form-control.form-dark:hover {
  background-color: #363636 !important;
  border: 1px solid #2F3133;
  color: #fff;
  outline: none;
}
.form-control.form-dark:focus {
  background-color: #363636 !important;
  border: 1px solid #2F3133;
  color: #fff;
  outline: none;
}
.form-control.form-dark:visited {
  background-color: #363636 !important;
  border: 1px solid #2F3133;
  color: #fff;
  outline: none;
}
.form-control.input-lg {
  font-size: 18px !important;
  height: 46px !important;
  line-height: 1.33;
  padding: 10px 16px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}
.form-control.input-sm {
  font-size: 12px !important;
  height: 30px !important;
  line-height: 1.5;
  padding: 5px 10px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.editableform .form-control {
  padding-right: 26px !important;
}
.editableform .btn.btn-sm {
  margin-right: 0 !important;
  padding-right: 6px !important;
  padding: 7px 0 !important;
}
.div_checkbox {
  margin-top: 0px;
  position: relative;
}
.input-icon {
  position: relative;
}
.input-icon i {
  color: #e5eaec;
  display: block;
  font-size: 16px;
  height: 16px;
  margin: 10px;
  position: absolute;
  text-align: center;
  width: 16px;
}
.input-icon input {
  padding-left: 34px !important;
}
.input-icon.right input {
  padding-left: 10px !important;
  padding-right: 34px !important;
}
.input-icon.right i {
  margin: 10px 0 10px;
  right: 17px;
}
.input-icon.right .fa-check {
  display: block;
}
.input-icon.right .parsley-success .fa-check {
  display: block;
}
.selectpicker li {
  list-style-type: none !important;
}
.switch-toggle {
  margin-bottom: 10px;
}
.dropdown-menu > li > a.no-option {
  height: 0;
  padding: 0;
}
.form-horizontal .control-label,
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  margin-bottom: 7px;
  margin-top: 0;
  padding-top: 0px;
}
.form-horizontal .control-label {
  color: #3E484E;
  text-align: left;
  top: 10px;
}
.form-horizontal .prepend-icon i {
  left: 15px;
}
.form-horizontal .append-icon i {
  right: 15px;
}
.form-horizontal .form-group {
  text-align: left;
}
.form-horizontal .row .col-md-12 {
  margin-left: 15px;
}
.bg-dark .form-horizontal .control-label {
  color: #adadad;
}
.bg-dark .form-error {
  color: #E4E4E4;
}
.bg-dark .form-success {
  color: #E4E4E4;
}
.file {
  position: relative;
}
.input-group-addon {
  border: none;
}
.append-icon {
  position: relative;
}
.append-icon input {
  padding-right: 36px;
}
.append-icon i {
  color: #1b1e25;
  color: rgba(27, 30, 37, 0.3);
  font-size: 14px;
  height: 34px;
  left: auto;
  line-height: 34px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 36px;
}
.append-icon .input-lg ~ i {
  font-size: 20px;
  height: 46px;
  line-height: 46px;
  width: 46px;
}
.append-icon .form-dark ~ i {
  color: #C3C3C3;
}
.prepend-icon {
  position: relative;
}
.prepend-icon input {
  padding-left: 36px;
}
.prepend-icon span {
  padding-left: 36px;
}
.prepend-icon i {
  color: #1b1e25;
  color: rgba(27, 30, 37, 0.3);
  font-size: 14px;
  height: 34px;
  left: 0;
  line-height: 34px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 36px;
}
.prepend-icon .form-dark ~ i {
  color: #C3C3C3;
}
.form-inline .append-icon {
  display: inherit !important;
}
.form-inline .prepend-icon {
  display: inherit !important;
}
.form-inline .after-tooltip {
  display: inherit !important;
}
.form-inline .before-tooltip {
  display: inherit !important;
}
.form-inline .inside-tooltip {
  display: inherit !important;
}
.form-inline label {
  margin-right: 10px;
}
.prepend-icon[class*="bg-"] ~ i {
  color: #F3F3F3;
}
.append-icon[class*="bg-"] ~ i {
  color: #F3F3F3;
}
.after-tooltip i {
  color: #1b1e25;
  color: rgba(27, 30, 37, 0.9);
  display: block;
  float: right;
  font-size: 14px;
  font-size: 16px;
  height: 34px;
  left: auto;
  line-height: 34px;
  right: 0;
  text-align: center;
  top: 0;
  width: 36px;
  width: 20%;
}
.after-tooltip input {
  float: left;
  width: 80%;
}
.after-tooltip .input-lg ~ i {
  font-size: 20px;
  height: 46px;
  line-height: 46px;
  width: 46px;
}
.after-tooltip input.input-sm ~ i {
  height: 33px;
  line-height: 33px;
}
.before-tooltip i {
  color: #1b1e25;
  color: rgba(27, 30, 37, 0.9);
  font-size: 14px;
  font-size: 16px;
  height: 34px;
  left: -18px;
  line-height: 34px;
  position: absolute;
  right: auto;
  text-align: center;
  top: 0;
  width: 36px;
}
.before-tooltip i.fa {
  font-size: 20px;
}
.before-tooltip .input-lg ~ i {
  font-size: 20px;
  height: 46px;
  line-height: 46px;
  width: 46px;
}
.before-tooltip .input-sm ~ i {
  font-size: 16px;
  height: 33px;
  line-height: 33px;
  width: 33px;
}
.inside-tooltip {
  position: relative;
}
.inside-tooltip i {
  color: #1b1e25;
  color: rgba(27, 30, 37, 0.9);
  font-size: 14px;
  height: 34px;
  left: 0;
  line-height: 34px;
  position: absolute;
  right: auto;
  text-align: center;
  top: 0;
  width: 36px;
}
.inside-tooltip i.fa {
  font-size: 20px;
}
.inside-tooltip .form-dark ~ i {
  color: #C3C3C3;
}
.inside-tooltip input {
  padding-left: 36px;
  padding-right: 0;
}
.inside-tooltip input.input-sm {
  padding-left: 28px;
  padding-left: 36px;
  padding-right: 0;
}
.inside-tooltip span {
  padding-left: 36px;
  padding-right: 0;
}
.inside-tooltip input.input-lg {
  padding-left: 42px;
}
.inside-tooltip input.input-lg ~ i {
  font-size: 26px;
  left: 22px;
  line-height: 48px;
}
.inside-tooltip input.input-sm ~ i {
  font-size: 15px;
  left: 15px;
  line-height: 33px;
}
[class*="col-"].inside-tooltip input ~ i {
  left: 15px;
}
form .custom-file {
  -moz-opacity: 0;
  bottom: 0;
  cursor: pointer;
  height: 100%;
  opacity: 0;
  padding: 8px 10px;
  position: absolute;
  right: 0;
  width: 100%;
  z-index: 11;
}
form .file-button {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  float: none;
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
  position: absolute;
  right: 1px;
  top: 1px;
  z-index: 10;
}
fieldset {
  border: 1px solid #E5E5E5;
  margin-top: 20px;
  padding: 20px;
}
legend {
  border-bottom: none;
  font-size: 15px;
  margin-bottom: 0;
  padding: 0 10px;
  width: auto;
}
.form-stripped {
  margin: 0 -20px -10px -20px;
}
.form-stripped .form-group {
  margin: 0;
  padding: 10px 0;
}
.form-stripped .form-group:nth-child(odd) {
  background-color: #F4F4F4;
}
.form-right .control-label {
  text-align: right;
}
.page-content.condensed {
  padding: 1px 10px 50px;
}
.condensed .form-group {
  margin-bottom: 5px;
}
.condensed .icheck-list > label {
  margin-bottom: 5px;
}
.condensed fieldset {
  margin-top: 10px;
  padding: 10px;
}
.main-content .page-content .panel .panel-content .input-group i {
  padding-left: 0;
  padding-right: 0;
}
.input-group input {
  min-width: 50px;
}
.input-group .fa.fa-angle-up {
  font-size: 12px;
}
.input-group .fa.fa-angle-down {
  font-size: 12px;
}
.input-group-btn:last-child > .btn {
  font-family: arial;
}
.input-group-btn:last-child > .btn-group {
  font-family: arial;
}
.input-group-btn:first-child > .btn.btn-white {
  border-right: 0 !important;
  margin-top: -1px;
  padding: 6px 24px 7px;
}
.input-group-btn:last-child > .btn.btn-white {
  border-left: 0 !important;
  margin-top: -1px;
  padding: 6px 24px 7px;
}
input.form-error,
input.form-error.form-control:focus,
textarea.form-error,
textarea.form-error.form-control:focus {
  background-color: #F7F2F2 !important;
  border: 1px solid #EED3D7 !important;
  color: #B94A48 !important;
}
.form-error {
  color: #313131;
  color: #994F4F;
  font-style: italic;
  margin-top: 10px;
}
.form-success {
  color: #313131;
  font-style: italic;
  margin-top: 10px;
}
input.error {
  background-color: #F7F2F2 !important;
  border: 1px solid #EED3D7 !important;
  color: #B94A48 !important;
}
label.error {
  color: #994F4F;
}
.form-control.form-error {
  color: #E4E4E4;
  margin-top: 0;
}
.form-control.form-success {
  color: #E4E4E4;
  margin-top: 0;
}
input.form-success,
input.form-success.form-control:focus,
textarea.form-success,
textarea.form-success.form-control:focus {
  background-color: #DFF0D8 !important;
  border: 1px solid #D6E9C6 !important;
  color: #468847 !important;
}
.field {
  display: block;
  position: relative;
}
.form-wizard .ui-radio label {
  background: transparent !important;
}
.form-wizard .ui-radio input {
  left: .6em;
  margin: -17px 0 0 0;
}
.form-wizard .ui-radio .ui-btn {
  z-index: 1;
}
.form-wizard .ui-checkbox input {
  left: .6em;
  margin: -17px 0 0 0;
}
.form-wizard .ui-checkbox .ui-btn {
  z-index: 1;
}
.form-wizard .ui-checkbox-off:after {
  filter: alpha(opacity=0);
  opacity: 0;
}
.form-wizard .ui-btn.ui-radio-off:after {
  filter: alpha(opacity=0);
  opacity: 0;
}
.bootstrap-touchspin .input-group-btn-vertical {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  white-space: nowrap;
  width: 1%;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  height: 19px;
  margin-bottom: 0 !important;
  width: 50px;
  -webkit-border-radius: 0 4px 0 0;
  -moz-border-radius: 0 4px 0 0;
  border-radius: 0 4px 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  height: 18px;
  margin-bottom: 0 !important;
  margin-top: -2px;
  width: 50px;
  -webkit-border-radius: 0 0 4px 0;
  -moz-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.bootstrap-touchspin .input-group-btn-vertical i {
  font-size: 9px;
  font-weight: normal;
  left: 21px;
  position: absolute;
  top: 5px;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn {
  display: block;
  float: none;
  margin-left: -1px;
  max-width: 100%;
  padding: 8px 10px;
  position: relative;
  width: 100%;
}
.bootstrap-touchspin-down {
  margin-bottom: 0 !important;
}
.bootstrap-touchspin-up {
  margin-bottom: 0 !important;
}
@media (max-width: 768px) {
  .form-horizontal .control-label,
  .form-horizontal .radio,
  .form-horizontal .checkbox,
  .form-horizontal .radio-inline,
  .form-horizontal .checkbox-inline {
    margin-bottom: 15px;
  }
  .col-md-5.border-right .col-md-6.border-right,
  .col-md-7.border-right,
  .col-md-8.border-right,
  .col-md-9.border-right,
  .col-md-10.border-right,
  .col-md-11.border-right,
  .col-md-12.border-right {
    border-right: none !important;
  }
  .form-horizontal .row .col-md-12 {
    margin-left: 0;
  }
  .form-horizontal .btn {
    width: 100%;
  }
  .form-inline {
    margin-left: 15px;
  }
  .form-inline .form-group.m-r-20 {
    margin-right: 0 !important;
  }
}
/**** Font Property ****/
.line-through {
  text-decoration: line-through;
}
.t-ellipsis {
  text-overflow: ellipsis;
  display: block;
  white-space: nowrap;
  overflow: hidden;
}
/****  Font Size  ****/
.bigger-110 {
  font-size: 110% !important;
}
.bigger-120 {
  font-size: 120% !important;
}
.f-5 {
  font-size: 5px !important;
}
.f-6 {
  font-size: 6px !important;
}
.f-7 {
  font-size: 7px !important;
}
.f-8 {
  font-size: 8px !important;
}
.f-9 {
  font-size: 9px !important;
}
.f-10 {
  font-size: 10px !important;
}
.f-11 {
  font-size: 11px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-13 {
  font-size: 13px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-15 {
  font-size: 15px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-17 {
  font-size: 17px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-19 {
  font-size: 19px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-24 {
  font-size: 24px !important;
}
.f-26 {
  font-size: 26px !important;
}
.f-28 {
  font-size: 28px !important;
}
.f-30 {
  font-size: 30px !important;
}
.f-32 {
  font-size: 32px !important;
}
.f-34 {
  font-size: 34px !important;
}
.f-36 {
  font-size: 36px !important;
}
.f-38 {
  font-size: 38px !important;
}
.f-40 {
  font-size: 40px !important;
}
.f-60 {
  font-size: 60px !important;
}
.f-65 {
  font-size: 65px !important;
}
.f-80 {
  font-size: 80px !important;
}
.f-150 {
  font-size: 150px !important;
}
.text-sm {
  font-size: 0.8em;
}
.text-2x {
  font-size: 2em;
}
.text-3x {
  font-size: 3em;
}
.text-4x {
  font-size: 4em;
}
/**** Text Position ****/
.t-center {
  text-align: center !important;
}
.t-left {
  text-align: left !important;
}
.t-right {
  text-align: right !important;
}
.l-through {
  text-decoration: line-through;
}
/****  Font Weight  ****/
.bold {
  font-weight: 600 !important;
}
.w-300 {
  font-weight: 300 !important;
}
.w-500 {
  font-weight: 500 !important;
}
.w-600 {
  font-weight: 600 !important;
}
.w-700 {
  font-weight: 700 !important;
}
/****  Font Color  ****/
.c-red {
  color: #cd6a6a;
}
.c-blue {
  color: #00A2D9;
}
.c-purple {
  color: #B57EE0;
}
.c-brown {
  color: #9E7B2E;
}
.c-orange {
  color: #ec8521;
}
.c-yellow {
  color: #F3D261;
}
.c-green {
  color: #18A689;
}
.c-gray-light {
  color: #dadada;
}
.c-gray {
  color: #8F8F8F;
}
.c-dark {
  color: #343434;
}
.c-white {
  color: #fff;
}
.c-light {
  color: #B3B3B3;
}
.c-primary {
  color: #319db5;
}
.transparent-color {
  color: rgba(0, 0, 0, 0.2);
}
.asterisk {
  color: #d9534f;
}
.help-block {
  color: #AFAAAA;
}
.text-primary {
  color: #319db5;
}
.switch {
  background-color: #fff;
  box-shadow: inset 0 -1px #ffffff, inset 0 1px 1px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin-top: 5px;
  padding: 3px;
  position: relative;
  vertical-align: top;
  width: 56px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
}
.switch-input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
}
.switch-input:checked ~ .switch-label {
  background: #319DB5;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15), inset 0 0 3px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-handle {
  box-shadow: -1px 1px 5px rgba(0, 0, 0, 0.4);
  left: 40px;
}
.switch-label {
  background: #eceeef;
  border-radius: inherit;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.12), inset 0 0 2px rgba(0, 0, 0, 0.15);
  display: block;
  font-size: 10px;
  height: inherit;
  position: relative;
  text-transform: uppercase;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  -ms-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
.switch-label:before {
  -moz-transition: inherit;
  -o-transition: inherit;
  -webkit-transition: inherit;
  color: #aaaaaa;
  content: attr(data-off);
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  right: 7px;
  text-shadow: 0 1px rgba(255, 255, 255, 0.5);
  top: 50%;
  transition: inherit;
}
.switch-label::after {
  -moz-transition: inherit;
  -o-transition: inherit;
  -webkit-transition: inherit;
  line-height: 1;
  margin-top: -0.5em;
  position: absolute;
  top: 50%;
  transition: inherit;
}
.switch-label:after {
  color: #fff;
  content: attr(data-on);
  left: 11px;
  opacity: 0;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}
.switch-input:checked ~ .switch-label::before {
  opacity: 0;
}
.switch-input:checked ~ .switch-label::after {
  opacity: 1;
}
.switch-handle {
  background: #fff;
  background-image: -moz-linear-gradient(top, #ffffff 40%, #f0f0f0);
  background-image: -o-linear-gradient(top, #ffffff 40%, #f0f0f0);
  background-image: -webkit-linear-gradient(top, #ffffff 40%, #f0f0f0);
  background-image: linear-gradient(to bottom, #ffffff 40%, #f0f0f0);
  border-radius: 10px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  height: 18px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 18px;
  -webkit-transition: left 0.15s ease-out;
  -moz-transition: left 0.15s ease-out;
  -o-transition: left 0.15s ease-out;
  -ms-transition: left 0.15s ease-out;
  transition: left 0.15s ease-out;
}
.switch-handle:before {
  background: #f9f9f9;
  background-image: -moz-linear-gradient(top, #eeeeee, #ffffff);
  background-image: -o-linear-gradient(top, #eeeeee, #ffffff);
  background-image: -webkit-linear-gradient(top, #eeeeee, #ffffff);
  background-image: linear-gradient(to bottom, #eeeeee, #ffffff);
  border-radius: 6px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.02);
  content: '';
  height: 12px;
  left: 50%;
  margin: -6px 0 0 -6px;
  position: absolute;
  top: 50%;
  width: 12px;
}
.switch-green > .switch-input:checked ~ .switch-label {
  background: #4fb845;
}
.ios-switch input[type="checkbox"] {
  margin: 0;
  opacity: 0;
  position: absolute;
}
.ios-switch input[type="checkbox"]:checked + div {
  background-position: 0 0;
  padding-left: 2em;
}
.ios-switch input[type="checkbox"]:active + div before {
  background-color: #eeeeee;
  text-align: left;
}
.ios-switch input[type="checkbox"] + div {
  background-clip: border-box;
  background: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), transparent), linear-gradient(90deg, #319db5 50%, transparent 50%);
  background-origin: border-box;
  background-position: 100% 0;
  background-size: 200% 100%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.1em 0.1em rgba(0, 0, 0, 0.2) inset, 0 0.45em 0 0.1em rgba(0, 0, 0, 0.05) inset;
  display: inline-block;
  font-size: 150%;
  height: 26px;
  margin: 0;
  overflow: hidden;
  text-align: left;
  transition-duration: .4s;
  transition-property: padding, width, background-position, text-indent;
  vertical-align: middle;
  width: 3em;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  border-radius: 999px;
}
.ios-switch input[type="checkbox"] + div:before {
  background: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), transparent);
  border-radius: inherit;
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1em 0.1em 0.1em rgba(255, 255, 255, 0.8) inset, 0 0 0.5em rgba(0, 0, 0, 0.3);
  color: #fff;
  content: 'On';
  float: left;
  font: bold 60%/1.9 sans-serif;
  height: 24px;
  margin: -3px;
  text-indent: -2.5em;
  text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.3);
  text-transform: uppercase;
  width: 24px;
}
.ios-switch input[type="checkbox"] + div:after {
  color: rgba(0, 0, 0, 0.25);
  content: 'Off';
  float: left;
  font: bold 60%/1.9 sans-serif;
  margin-top: -2px;
  text-indent: .5em;
  text-shadow: none;
  text-transform: uppercase;
}
label.ios-switch {
  background: #fff;
  border-top-width: 0;
  display: block;
  font: bold 110% sans-serif;
  position: relative;
}
label.ios-switch:first-of-type {
  border-top-width: 1px;
  -webkit-border-radius: 0.6em 0.6em 0 0;
  -moz-border-radius: 0.6em 0.6em 0 0;
  border-radius: 0.6em 0.6em 0 0;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
label.ios-switch:last-of-type {
  box-shadow: 0 1px rgba(255, 255, 255, 0.8);
  -webkit-border-radius: 0 0 0.6em 0.6em;
  -moz-border-radius: 0 0 0.6em 0.6em;
  border-radius: 0 0 0.6em 0.6em;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}
.onoffswitch {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  float: left;
  margin-right: 20px;
  position: relative;
  width: 100px;
}
.onoffswitch .onoffswitch-checkbox {
  display: none;
}
.onoffswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch .onoffswitch-label {
  border: 0 solid #999999;
  cursor: pointer;
  display: block;
  overflow: hidden;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.onoffswitch .onoffswitch-inner {
  -moz-transition: margin 0.3s ease-in 0;
  -o-transition: margin 0.3s ease-in 0;
  -webkit-transition: margin 0.3s ease-in 0;
  display: block;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0;
  width: 200%;
}
.onoffswitch .onoffswitch-inner .onoffswitch-active {
  background-color: #C2C2C2;
  color: #fff;
  padding-left: 15px;
}
.onoffswitch .onoffswitch-inner .onoffswitch-inactive {
  background-color: #C2C2C2;
  color: #fff;
  padding-right: 15px;
  text-align: right;
}
.onoffswitch .onoffswitch-inner > span {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  display: block;
  float: left;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 30px;
  line-height: 30px;
  padding: 0;
  position: relative;
  width: 50%;
}
.onoffswitch .onoffswitch-switch {
  border: 0 solid #999999;
  bottom: 0;
  display: block;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 40px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.onoffswitch .onoffswitch-active .onoffswitch-switch {
  background: #34A7C1;
  left: 0;
}
.onoffswitch .onoffswitch-active .onoffswitch-switch::before {
  border-color: #34A7C1 transparent transparent #34A7C1;
  border-style: solid;
  border-width: 15px 10px;
  content: " ";
  left: 40px;
  position: absolute;
  top: 0;
}
.onoffswitch .onoffswitch-inactive .onoffswitch-switch {
  background: #A1A1A1;
  right: 0;
}
.onoffswitch .onoffswitch-inactive .onoffswitch-switch::before {
  border-color: transparent #A1A1A1 #A1A1A1 transparent;
  border-style: solid;
  border-width: 15px 10px;
  content: " ";
  position: absolute;
  right: 40px;
  top: 0;
}
.onoffswitch2 {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  float: left;
  margin-right: 20px;
  position: relative;
  width: 73px;
}
.onoffswitch2 .onoffswitch-checkbox {
  display: none;
}
.onoffswitch2 .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch2 .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0;
}
.onoffswitch2 .onoffswitch-label {
  border-radius: 3px;
  cursor: pointer;
  display: block;
  overflow: hidden;
}
.onoffswitch2 .onoffswitch-inner {
  display: block;
  margin-left: -100%;
  width: 200%;
  -webkit-transition: margin 0.3s ease-in;
  -moz-transition: margin 0.3s ease-in;
  -o-transition: margin 0.3s ease-in;
  -ms-transition: margin 0.3s ease-in;
  transition: margin 0.3s ease-in;
}
.onoffswitch2 .onoffswitch-inner::before {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #34A7C1;
  box-sizing: border-box;
  color: #fff;
  content: "ON";
  display: block;
  float: left;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0;
  padding-left: 8px;
  width: 50%;
}
.onoffswitch2 .onoffswitch-inner::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  background-color: #eeeeee;
  box-sizing: border-box;
  color: #999999;
  color: #fff;
  content: "OFF";
  display: block;
  float: left;
  font-family: Trebuchet, Arial, sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  padding: 0;
  padding-right: 8px;
  text-align: right;
  width: 50%;
}
.onoffswitch2 .onoffswitch-switch {
  background: #fff;
  border-radius: 3px;
  border: 2px solid #FFFFF;
  bottom: 0;
  display: block;
  height: 14px;
  margin: 5px;
  position: absolute;
  right: 45px;
  top: 0;
  width: 14px;
  -webkit-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}
.switchery {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid #dfdfdf;
  box-sizing: content-box;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  position: relative;
  vertical-align: middle;
  width: 50px;
}
.switchery > small {
  background: #fff;
  height: 30px;
  position: absolute;
  top: 0;
  width: 30px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}
.small + .switchery {
  height: 40px;
  width: 60px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.small + .switchery > small {
  height: 40px;
  width: 40px;
}
.big + .switchery {
  height: 50px;
  width: 80px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
}
.big + .switchery > small {
  height: 50px;
  width: 50px;
}
.select2-search-choice-close {
  background: none !important;
}
.select2-search-choice-close:before {
  content: "x";
  display: inline-block;
  font-size: 13px;
  padding-left: 3px;
}
.select2-container-multi .select2-choices .select2-search-choice {
  background: #0A8CC3;
  background: #64AADD !important;
  border-color: #0A8CC3;
  border-color: #64AADD !important;
  color: #FFFFFF;
  color: #ffffff !important;
}
.select2-container-multi .select2-choices .select2-search-choice:hover {
  background: #009adb !important;
}
.select2-container-multi .select2-search-choice-close {
  color: #ffffff;
  left: 3px;
}
.select2-container .select2-choice {
  -webkit-box-shadow: none !important;
  background-color: #ecedee !important;
  border: 1px solid #ecedee !important;
  box-shadow: none !important;
}
.select2-container .select2-choice .select2-arrow b {
  background: none !important;
}
.select2-container .select2-choice .select2-arrow b:before {
  content: "\f0d7";
  font-family: 'FontAwesome';
  font-size: 12px;
  position: relative;
  right: 5px;
  top: 6px;
}
.select2-container .select2-choices {
  -webkit-box-shadow: none !important;
  background-color: #ecedee !important;
  border: 1px solid #ecedee !important;
  box-shadow: none !important;
}
.select2-container .select2-choices .select2-search-field input {
  -webkit-box-shadow: none !important;
  background-color: #ecedee !important;
  border: 1px solid #ecedee !important;
  box-shadow: none !important;
}
.select2-dropdown-open .select2-choice .select2-arrow b:before {
  content: "\f0d8";
}
.select2-drop {
  background-color: #ecedee !important;
  box-shadow: none !important;
  outline: none !important;
}
.select2-drop-active {
  border-top: none !important;
  border: 1px solid #DDDFE1 !important;
  box-shadow: none !important;
}
.select2-container-active .select2-choice {
  border: 1px solid #DDDFE1 !important;
  box-shadow: none !important;
}
.select2-container-multi.select2-container-active .select2-choices {
  border: 1px solid #DDDFE1 !important;
  box-shadow: none !important;
}
.select2-drop-auto-width {
  border: 1px solid #DDDFE1 !important;
  box-shadow: none !important;
}
.select2-drop.select2-drop-above.select2-drop-active {
  border: 1px solid #DDDFE1 !important;
  box-shadow: none !important;
}
.select2-results .select2-highlighted {
  background-color: #2B2E33;
}
.ios-slider.slider {
  display: inline-block;
  position: relative;
  vertical-align: middle;
}
.ios-slider.slider input {
  display: none;
}
.ios-slider.slider .tooltip-inner {
  white-space: nowrap;
}
.ios-slider.slider.slider-horizontal {
  height: 20px;
  width: 100%;
}
.ios-slider.slider.slider-horizontal .slider-track {
  height: 5px;
  left: 0;
  margin-top: -5px;
  top: 50%;
  width: 100%;
}
.ios-slider.slider.slider-horizontal .slider-selection {
  bottom: 0;
  height: 100%;
  top: 0;
}
.ios-slider.slider.slider-horizontal .slider-handle {
  margin-left: -10px;
  margin-top: -13px;
  outline: none;
}
.ios-slider.slider.slider-horizontal .slider-handle.triangle {
  border-bottom-color: #0480be;
  border-width: 0 10px 10px 10px;
  height: 0;
  margin-top: 0;
  width: 0;
}
.ios-slider.slider.slider-horizontal .tooltip {
  margin-left: 5px;
  margin-top: -12px;
}
.ios-slider.slider.slider-vertical {
  height: 210px;
  margin-right: 70px;
  width: 20px;
}
.ios-slider.slider.slider-vertical .slider-track {
  height: 100%;
  left: 50%;
  margin-left: -5px;
  top: 0;
  width: 5px;
}
.ios-slider.slider.slider-vertical .slider-selection {
  bottom: 0;
  left: 0;
  top: 0;
  width: 100%;
}
.ios-slider.slider.slider-vertical .slider-handle {
  margin-left: -13px;
  margin-right: 0;
  margin-top: -10px;
  text-align: center;
}
.ios-slider.slider.slider-vertical .slider-handle.triangle {
  border-left-color: #0480be;
  border-width: 10px 0 10px 10px;
  height: 1px;
  margin-left: 0;
  width: 1px;
}
.ios-slider.slider.slider-vertical .tooltip.right {
  margin-top: 5px;
}
.rtl .ios-slider.slider.slider-vertical {
  margin-left: 70px;
  margin-right: 0;
}
.rtl .ios-slider.slider.slider-vertical .slider-handle {
  margin-left: 0;
  margin-right: -13px;
}
.ios-slider .slider-track {
  -moz-border-radius: 4px;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #2b3647;
  background-repeat: repeat-x;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#fff9f9f9', GradientType=0);
  position: absolute;
}
.ios-slider .slider-selection {
  -moz-border-radius: 4px;
  -moz-box-sizing: border-box;
  -webkit-border-radius: 4px;
  -webkit-box-sizing: border-box;
  background-color: #2b3647;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
}
.ios-slider .slider-handle {
  -moz-box-shadow: inset 0 2px 1px -1px #ffffff, 0px 1px 3px rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: inset 0 2px 1px -1px #ffffff, 0px 1px 3px rgba(0, 0, 0, 0.4);
  background-color: #ffffff;
  background-image: none;
  box-shadow: inset 0 2px 1px -1px #ffffff, 0px 1px 3px rgba(0, 0, 0, 0.4);
  height: 30px;
  position: absolute;
  width: 30px;
}
.ios-slider .slider-handle.round {
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.ios-slider .slider-handle.triangle {
  background: transparent none;
}
.danger .ios-slider .slider-selection {
  background-color: #c75757;
}
.primary .ios-slider .slider-selection {
  background-color: #319db5;
}
.success .ios-slider .slider-selection {
  background-color: #18a689;
}
.warning .ios-slider .slider-selection {
  background-color: #ff9122;
}
.dark .ios-slider .slider-selection {
  background-color: #353940;
}
.danger .ios-slider .slider-track,
.primary .ios-slider .slider-track,
.success .ios-slider .slider-track,
.warning .ios-slider .slider-track,
.dark .ios-slider .slider-track {
  background-color: #f0f0f0;
}
.form-group .tips {
  color: #A8A8A8;
  font-style: italic;
}
.form-group strong {
  font-weight: 600;
}
.input-group .form-control {
  height: 35px;
}
.ui-timepicker-div dl {
  border-width: 1px 0 0 0;
  border: 1px solid #cfcfcf;
  margin: 0;
  padding: 15px 10px;
  text-align: left;
}
.ui-timepicker-div dl dt {
  clear: left;
  float: left;
  padding: 0 0 0 5px;
}
.ui-timepicker-div dl dd {
  margin: 0 10px 10px 40%;
  margin: 0 10px 20px 40%;
}
.ui-timepicker-div td {
  font-size: 90%;
}
.ui-timepicker-div .slider-wrapper {
  background: #e5e5e5;
  position: relative;
}
.ui-timepicker-div .sliderv-wrapper {
  background: #e5e5e5;
  position: relative;
}
.ui-timepicker-div .ui-slider {
  position: relative;
  text-align: left;
}
.ui-timepicker-div .ui-slider .ui-slider-handle {
  -moz-border-radius: 20px;
  -ms-touch-action: none;
  -o-border-radius: 20px;
  -webkit-border-radius: 20px;
  background: #fff;
  border-radius: 20px;
  border: 3px solid #319db5;
  cursor: default;
  height: 1.5em;
  margin-top: -3px;
  outline: 0;
  position: absolute;
  touch-action: none;
  width: 1.5em;
  z-index: 2;
}
.ui-timepicker-div .ui-slider .ui-slider-handle:before {
  -moz-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-border-radius: 10px;
  background-color: #319db5;
  border-radius: 10px;
  content: '';
  height: 7px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 7px;
  z-index: 2;
}
.ui-timepicker-div .ui-slider .ui-slider-range {
  background-color: #319db5;
  background-position: 0 0;
  border: 0;
  display: block;
  font-size: .7em;
  position: absolute;
  z-index: 1;
}
.ui-timepicker-div .ui-slider.ui-state-disabled .ui-slider-handle {
  filter: inherit;
}
.ui-timepicker-div .ui-slider.ui-state-disabled .ui-slider-range {
  filter: inherit;
}
.ui-timepicker-div .ui-slider-horizontal {
  height: .5em;
}
.ui-timepicker-div .ui-slider-horizontal .ui-slider-handle {
  margin-left: -0.6em;
  top: -0.3em;
}
.ui-timepicker-div .ui-slider-horizontal .ui-slider-range {
  height: 100%;
  top: 0;
}
.ui-timepicker-div .ui-slider-horizontal .ui-slider-range-min {
  left: 0;
}
.ui-timepicker-div .ui-slider-horizontal .ui-slider-range-max {
  right: 0;
}
.ui-timepicker-div .ui-widget-header {
  background: #1B1E25;
  font-size: 15px;
  line-height: 27px;
  padding: 10px;
}
.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.ui-timepicker-rtl {
  direction: rtl;
}
.ui-timepicker-rtl dl {
  padding: 0 5px 0 0;
  text-align: right;
}
.ui-timepicker-rtl dl dt {
  clear: right;
  float: right;
}
.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}
.ui-datepicker {
  -moz-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  background: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  color: #666666;
  display: none;
  font-size: 14px;
  margin-top: 8px;
  position: relative;
  text-align: center;
  width: 18em;
  z-index: 10;
}
.ui-datepicker a {
  color: #404040;
  text-align: center;
}
.ui-datepicker .ui-state-disabled span {
  color: #dbdbdb;
}
.ui-datepicker .ui-state-disabled span:hover {
  background: 0;
}
.ui-datepicker .ui-datepicker-header {
  background: #1B1E25;
  border-bottom: 1px solid #cfcfcf;
  font-size: 15px;
  line-height: 27px;
  padding: 10px;
  position: relative;
}
.ui-datepicker .ui-datepicker-prev {
  color: #D3D3D3;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 34px;
  left: 2px;
  position: absolute;
  text-decoration: none;
  top: 20.5%;
  width: 34px;
}
.ui-datepicker .ui-datepicker-next {
  color: #D3D3D3;
  cursor: pointer;
  display: block;
  font-size: 14px;
  height: 34px;
  position: absolute;
  right: 2px;
  text-decoration: none;
  top: 20.5%;
  width: 34px;
}
.ui-datepicker .ui-datepicker-title {
  color: #E9E9E9;
  font-family: 'Lato';
  font-weight: 100;
  margin: 0 2.3em;
  text-align: center;
}
.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}
.ui-datepicker select.ui-datepicker-month-year {
  width: 100%;
}
.ui-datepicker select.ui-datepicker-month {
  width: 49%;
}
.ui-datepicker select.ui-datepicker-year {
  width: 49%;
}
.ui-datepicker table {
  border-collapse: collapse;
  font-size: .9em;
  margin: 0 0 .4em;
  width: 100%;
}
.ui-datepicker th {
  border: 0;
  font-weight: bold;
  padding: .5em .3em;
  text-align: center;
}
.ui-datepicker td {
  border: 0;
  padding: 2px 5px;
}
.ui-datepicker td span {
  display: block;
  padding: .25em;
  text-align: center;
  text-decoration: none;
}
.ui-datepicker td span:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.ui-datepicker td a {
  display: block;
  padding: .25em;
  text-align: center;
  text-decoration: none;
}
.ui-datepicker td a:hover {
  background: #f5f5f5;
  color: #3C3C3C;
}
.ui-datepicker .ui-state-highlight {
  color: #fff;
  font-weight: 700;
}
.ui-datepicker-today a {
  background: #B3C5C7 !important;
  color: #616060;
  font-weight: 700;
}
.ui-datepicker-today a:hover {
  background: #319db5 !important;
  color: #fff;
  font-weight: 700;
}
.ui-datepicker-current-day .ui-state-active {
  background: #319db5 !important;
  color: #fff;
  font-weight: 700;
}
.cal-widget .ui-datepicker {
  margin-top: 0;
  width: 100%;
}
.cal-widget .ui-datepicker:before {
  display: none;
}
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}
.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0 auto .4em;
  width: 95%;
}
.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}
.ui-monthpicker .ui-datepicker-header {
  margin-bottom: 3px;
}
.ui-timepicker-div dl .ui_tpicker_hour,
.ui-timepicker-div dl .ui_tpicker_minute,
.ui-timepicker-div dl .ui_tpicker_second,
.ui-timepicker-div dl .ui_tpicker_millisec {
  -moz-border-radius: 4px;
  -moz-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 4px;
  background-color: #F0F0F0;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  position: relative;
  top: 6px;
  webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.ui-datepicker-buttonpane {
  background: #ECECEC;
  border-top: 1px solid #ECECEC;
  padding: 10px;
}
.ui-datepicker-buttonpane button {
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
  background-color: #2B2E33;
  border-radius: 4px;
  border: none;
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin-right: 10px;
  padding: 7px 24px 8px 24px;
  transition: border 0.25s linear, color 0.25s linear, background-color 0.25s linear;
}
.ui-datepicker-buttonpane button:hover {
  background-color: #1F2225;
  border-color: #1F2225;
  color: #fff;
}
.ui-datepicker-buttonpane button.ui-datepicker-current {
  background-color: #687386;
}
.ui-datepicker-buttonpane button.ui-datepicker-current:hover {
  background-color: #5d6778;
}
.ui-datepicker-inline {
  width: 100%;
}
.datepicker table tr td span {
  text-align: center;
}
/* Bootstrap Datepicker */
.datepicker {
  border-radius: 0;
}
.datepicker table tr td.today {
  background-color: #E3E6E8 !important;
  background-image: none!important;
  border-color: #E3E6E8 !important;
  border-radius: 0!important;
  color: #121212 !important;
}
.datepicker table tr td.today:hover {
  background-color: #E3E6E8 !important;
  background-image: none!important;
  border-color: #E3E6E8 !important;
  border-radius: 0!important;
  color: #121212 !important;
}
.datepicker table tr td.today.disabled {
  background-color: #E3E6E8 !important;
  background-image: none!important;
  border-color: #E3E6E8 !important;
  border-radius: 0!important;
  color: #121212 !important;
}
.datepicker table tr td.today.disabled:hover {
  background-color: #E3E6E8 !important;
  background-image: none!important;
  border-color: #E3E6E8;
  border-radius: 0!important;
  color: #121212 !important;
}
.datepicker table tr td {
  border-radius: 0;
}
.datepicker table tr th {
  border-radius: 0;
}
.datepicker table tr td.selected {
  background-color: #999999 !important;
  background-image: none!important;
  border-color: #555555 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}
.datepicker table tr td.selected:hover {
  background-color: #999999 !important;
  background-image: none!important;
  border-color: #555555 !important;
  color: #ffffff !important;
  text-shadow: none;
}
.datepicker table tr td.selected.disabled {
  background-color: #999999 !important;
  background-image: none!important;
  border-color: #555555 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}
.datepicker table tr td.selected.disabled:hover {
  background-color: #999999 !important;
  background-image: none!important;
  border-color: #555555 !important;
  color: #ffffff !important;
  text-shadow: none !important;
}
.input-daterange input {
  text-align: left;
}
.input-daterange .input-group-addon {
  background: #ffffff !important;
  border: none !important;
}
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td span.active,
.open .dropdown-toggle.datepicker table tr td span.active:hover,
.open .dropdown-toggle.datepicker table tr td span.active.disabled,
.open .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
  background-color: #2b2e33 !important;
  border-color: #2b2e33 !important;
  border-radius: 0!important !important;
  color: #ffffff !important;
}
.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:focus,
.datepicker table tr td.today:hover:focus,
.datepicker table tr td.today.disabled:focus,
.datepicker table tr td.today.disabled:hover:focus,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.today,
.open .dropdown-toggle.datepicker table tr td.today:hover,
.open .dropdown-toggle.datepicker table tr td.today.disabled,
.open .dropdown-toggle.datepicker table tr td.today.disabled:hover {
  background-color: #DDE1E3 !important;
  background-image: none!important;
  border-color: #DDE1E3 !important;
  border-radius: 0!important;
  color: #121212 !important;
}
.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:focus,
.datepicker table tr td.active:hover:focus,
.datepicker table tr td.active.disabled:focus,
.datepicker table tr td.active.disabled:hover:focus,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.open .dropdown-toggle.datepicker table tr td.active,
.open .dropdown-toggle.datepicker table tr td.active:hover,
.open .dropdown-toggle.datepicker table tr td.active.disabled,
.open .dropdown-toggle.datepicker table tr td.active.disabled:hover {
  background-color: #2b2e33 !important;
  border-color: #2b2e33 !important;
  border-radius: 0!important;
  color: #ffffff !important;
}
.datepicker-dropdown.datepicker-orient-bottom:before,
.datepicker-dropdown.datepicker-orient-bottom:after {
  top: auto;
}
.ui-timepicker-div .ui-widget-header {
  margin-bottom: 8px;
}
.ui-timepicker-div dl {
  text-align: left;
}
.ui-timepicker-div dl dt {
  clear: left;
  float: left;
  padding: 0 0 0 5px;
}
.ui-timepicker-div dl dd {
  margin: 0 10px 20px 40%;
}
.ui-timepicker-div td {
  font-size: 90%;
}
.ui-tpicker-grid-label {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
}
.ui-timepicker-rtl {
  direction: rtl;
}
.ui-timepicker-rtl dl {
  padding: 0 5px 0 0;
  text-align: right;
}
.ui-timepicker-rtl dl dt {
  clear: right;
  float: right;
}
.ui-timepicker-rtl dl dd {
  margin: 0 40% 10px 10px;
}
.pagination > li > a {
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  background: none;
  border: none;
  color: #636e7b;
}
.pagination > li > span {
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  background: none;
  border: none;
  color: #636e7b;
}
.pagination > li:first-child > a {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination > li:first-child > span {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination > li:last-child > a {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background: none;
}
.pagination li {
  display: inline-block;
  float: left;
  margin-left: 5px;
}
.pagination li:first-child {
  margin-left: 0;
}
.pagination li a {
  color: #A2A2A2;
  font-size: 12px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background: none;
  border: none;
  color: #3C3C3C;
}
ul.pagination > .disabled > span,
ul.pagination > .disabled > a,
ul.pagination > .disabled > a i,
ul.pagination > .disabled > a:hover,
ul.pagination > .disabled > a:focus {
  opacity: 0 !important;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span,
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  padding: 6px 6px 4px 6px !important;
}
.pagination2 th {
  font-size: 12px;
}
.pagination2 .pagination {
  background: #EFEFEF;
  border: 1px solid #E2E2E2;
  float: right;
}
.pagination2 .pagination li {
  height: 29px !important;
  margin-left: 0;
}
.pagination2 .pagination li a {
  height: 29px;
  line-height: 20px;
}
.pagination2 .fa-angle-left:before {
  content: "Prev";
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, sans-serif;
  line-height: 20px;
}
.pagination2 .fa-angle-right:before {
  content: "Next";
  font-family: 'Lato', 'Lucida Grande', 'Lucida Sans Unicode', Helvetica, sans-serif;
  line-height: 20px;
}
.pagination2 .pagination > li > a {
  background-color: #EFEFEF;
}
.pagination2 .pagination > li > a:hover {
  background-color: #D3D3D3;
}
.pagination2 .pagination > li > span {
  background-color: #EFEFEF;
}
.pagination2 .pagination > li > span:hover {
  background-color: #D3D3D3;
}
.pagination2 ul.pagination > .disabled > span,
.pagination2 ul.pagination > .disabled > a,
.pagination2 ul.pagination > .disabled > a i,
.pagination2 ul.pagination > .disabled > a:hover,
.pagination2 ul.pagination > .disabled > a:focus {
  background-color: #EFEFEF;
  line-height: 20px;
  opacity: 0.7 !important;
}
.pagination2 .pagination > .active > a,
.pagination2 .pagination > .active > span,
.pagination2 .pagination > .active > a:hover,
.pagination2 .pagination > .active > span:hover,
.pagination2 .pagination > .active > a:focus,
.pagination2 .pagination > .active > span:focus {
  background: #ef4a23;
  border: none;
  color: #ffffff;
  height: 31px;
  margin-top: -1px;
}
.tabcordion .tab-content {
  box-shadow: none;
}
.nav-tabs > li > a::before {
  -moz-transition: -moz-transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  background: #566473;
  content: '';
  height: 3px;
  left: 0;
  position: absolute;
  top: 40px;
  transition: transform 0.3s;
  width: 100%;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.nav-tabs.nav-primary > li > a::before {
  background-color: #319db5;
}
.nav-tabs.nav-blue > li > a::before {
  background-color: #00a2d9;
}
.nav-tabs.nav-red > li > a::before {
  background-color: #c75757;
}
.nav-tabs.nav-green > li > a::before {
  background-color: #18a689;
}
.nav-tabs.nav-yellow > li > a::before {
  background-color: #ff9122;
}
.nav-tabs .active a::before {
  width: 100%;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.nav-tabs a:hover::before {
  width: 100%;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.nav-tabs a:focus::before {
  width: 100%;
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.nav-tabs:after {
  bottom: 3px;
  position: relative;
  width: 100%;
  z-index: 120;
}
.nav.nav-tabs > li > a {
  background-color: #fff;
  border: none;
  color: #939393;
}
.nav.nav-tabs > li > a i {
  padding-right: 8px;
}
.nav.nav-tabs > li.active > a {
  background-color: #fff;
  border-bottom: none;
  color: #121212;
}
.nav.nav-tabs > li:hover > a {
  background-color: #fff;
  color: #121212;
}
.tab-content {
  background-color: #fff;
  border-top: 1px solid #dfdfdf;
  padding: 15px;
}
.tab_left > .tab-content {
  border-left: 2px solid #dfdfdf;
  border-top: none;
  overflow: hidden;
}
.tab_left .nav-tabs a::before {
  -moz-transition: -moz-transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  content: '';
  height: 100%;
  left: auto;
  position: absolute;
  right: -2px;
  top: 0;
  width: 2px;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.tab_left .nav-tabs .active a::before {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.tab_left .nav-tabs a:focus::before {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.tab_left > .nav-tabs {
  border-bottom: none;
  border-right: 1px solid #dddddd;
  float: left;
  margin-right: 0;
}
.tab_left > .nav-tabs > li {
  float: none;
}
.tab_left > .nav-tabs > li > a {
  border-radius: 0;
  border: none;
  margin-right: -1px;
  margin-right: 0;
  min-width: 80px;
}
.tab_right > .nav-tabs {
  border-bottom: none;
  border-left: 1px solid #dddddd;
  float: right;
  margin-left: 0px;
}
.tab_right > .tab-content {
  border-right: 2px solid #dfdfdf;
  border-top: none;
  overflow: hidden;
}
.tab_right .nav-tabs a::before {
  -moz-transition: -moz-transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  content: '';
  height: 100%;
  left: -2px;
  position: absolute;
  right: auto;
  top: 0;
  width: 2px;
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -webkit-transform: scale(0);
  transform: scale(0);
}
.tab_right .nav-tabs .active a::before {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.tab_right .nav-tabs a:focus::before {
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -webkit-transform: scale(1);
  transform: scale(1);
}
.tab_right > .nav-tabs > li {
  float: none;
}
.tab_right > .nav-tabs > li > a {
  border-radius: 0;
  border: none;
  margin-left: -1px;
  margin-left: 0;
  min-width: 80px;
}
body.DTTT_Print {
  background: none;
}
.DTTT_Print .main-content {
  margin-left: 0;
}
.nav-tabs > li {
  margin-bottom: 0;
}
.nav-tabs > li:after {
  -webkit-backface-visibility: hidden;
  -webkit-transition: all .1s linear 0;
  background-color: #f55753;
  content: '';
  display: block;
  height: 0;
  transition: all .1s linear 0;
  width: 100%;
  z-index: 125;
}
.nav-tabs.nav-4 > li {
  width: 25%;
}
.nav-tabs > li.active a {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs > li.active a:hover {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs > li.active a:focus {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs-primary > li:after {
  background-color: #319db5;
}
.nav-tabs-success > li:after {
  background-color: #18a689;
}
.nav-tabs-danger > li:after {
  background-color: #c75757;
}
.nav-tabs-warning > li:after {
  background-color: #ff9122;
}
.nav-tabs-info > li:after {
  background-color: #b066ff;
}
.nav-tabs > li.active > a {
  border: none;
}
.nav-tabs > li.active > a:hover {
  border: none;
}
.nav-tabs > li.active > a:focus {
  border: none;
}
.nav-tabs2 > .nav-tabs {
  border-bottom: none;
}
.nav-tabs2 > .nav-tabs a::before {
  background: none !important;
}
.nav-tabs2 > .nav-tabs:after {
  bottom: 0;
}
.nav-tabs2 > .nav.nav-tabs .open > a {
  border-width: 0;
  border: none;
}
.nav-tabs2 .nav.nav-tabs .open > a:hover {
  border-width: 0;
  border: none;
}
.nav-tabs2 .nav.nav-tabs .open > a:focus {
  border-width: 0;
  border: none;
}
.nav-tabs2 .tab_left > .nav-tabs {
  border-right: 1px solid #dddddd;
  float: left;
  margin-right: 0;
}
.nav-tabs2 .tab_left > .nav-tabs > li {
  float: none;
}
.nav-tabs2 .tab_left > .nav-tabs > li > a {
  border-radius: 0;
  border: none;
  margin-right: -1px;
  margin-right: 0;
  min-width: 80px;
}
.nav-tabs2 .tab_right > .tab-content {
  overflow: hidden;
}
.nav-tabs2 .tab_right > .nav-tabs {
  border-left: 1px solid #dddddd;
  float: right;
  margin-left: 0px;
}
.nav-tabs2 .tab_right > .nav-tabs > li {
  float: none;
}
.nav-tabs2 .tab_right > .nav-tabs > li > a {
  border-radius: 0;
  border: none;
  margin-left: -1px;
  margin-left: 0;
  min-width: 80px;
}
.nav-tabs2 > .nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs2 > .nav-tabs > li:after {
  background: none !important;
}
.nav-tabs2 > .nav-tabs > li > a {
  border: none;
}
.nav-tabs2 > .nav.nav-tabs > li > a {
  background-color: #fff;
  color: #121212;
}
.nav-tabs2 > .nav.nav-tabs.nav-dark > li > a {
  background-color: #353940;
  color: #dadada;
}
.nav-tabs2 > .nav.nav-tabs > li.active > a {
  background-color: #F1F1F1;
  border-left: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-top: 1px solid #dfdfdf !important;
  border: none;
  color: #121212;
}
.nav-tabs2 > .tab-content {
  background-color: #F1F1F1;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  padding: 15px;
}
.nav-tabs2 > .nav-tabs.nav-dark .dropdown-menu {
  background-color: #353940;
}
.nav-tabs2 > .nav.nav-tabs.nav-dark .dropdown-menu a {
  color: #dadada;
}
.nav-tabs2 > .nav.nav-tabs.nav-dark .dropdown-menu li:hover {
  background: #373a41;
}
.nav-tabs2 > .nav.nav-tabs.nav-dark .dropdown-arrow-inverse {
  border-bottom-color: #353940 !important;
  border-top-color: #353940 !important;
}
.nav-tabs2 > .nav.nav-tabs.nav-dark .dropdown-inverse li > a:hover {
  background: #373a41;
}
.nav.nav-tabs.nav-dark .dropdown-inverse li > a:active {
  background: #373a41;
}
.nav.nav-tabs.nav-dark .dropdown-inverse li > a:focus {
  background: #373a41;
}
.nav-tabs3 > .nav-tabs {
  border-bottom: none;
}
.nav-tabs3 > .nav-tabs a::before {
  background: none !important;
  content: '';
}
.nav-tabs3 > .nav-tabs:after {
  bottom: 0;
}
.nav-tabs3 > .nav.nav-tabs .open > a {
  border-width: 0;
  border: none;
}
.nav-tabs3 > .nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs3 > .nav-tabs > li:after {
  background: none !important;
}
.nav-tabs3 > .nav-tabs > li > a {
  border: none;
}
.nav-tabs3 > .nav.nav-tabs > li > a {
  background-color: #CFDAE0;
  color: #121212;
}
.nav-tabs3 > .nav.nav-tabs.nav-dark > li > a {
  background-color: #353940;
  color: #dadada;
}
.nav-tabs3 > .nav.nav-tabs > li.active > a {
  background-color: #F1F1F1;
  border: none;
  color: #121212;
}
.nav-tabs3 > .tab-content {
  background-color: #F1F1F1;
  border-top: none !important;
  overflow: hidden;
  padding: 15px;
}
.nav-tabs3 > .nav-tabs.nav-dark .dropdown-menu {
  background-color: #353940;
}
.nav-tabs3 > .nav.nav-tabs.nav-dark .dropdown-menu a {
  color: #dadada;
}
.nav-tabs3 > .nav.nav-tabs.nav-dark .dropdown-menu li:hover {
  background: #373a41;
}
.nav-tabs3 > .nav.nav-tabs.nav-dark .dropdown-arrow-inverse {
  border-bottom-color: #353940 !important;
  border-top-color: #353940 !important;
}
.nav-tabs3 > .nav.nav-tabs.nav-dark .dropdown-inverse li > a:hover {
  background: #373a41;
}
.nav-tabs3 .tab_left > .nav-tabs {
  border-right: 1px solid #dddddd;
  float: left;
  margin-right: 0;
}
.nav-tabs3 .tab_left > .nav-tabs > li {
  float: none;
}
.nav-tabs3 .tab_left > .nav-tabs > li > a {
  border: none;
  margin-right: -1px;
  margin-right: 0;
  min-width: 80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs3 .tab_right > .tab-content {
  overflow: hidden;
}
.nav-tabs3 .tab_right > .nav-tabs {
  border-left: 1px solid #dddddd;
  float: right;
  margin-left: 0px;
}
.nav-tabs3 .tab_right > .nav-tabs > li {
  float: none;
}
.nav-tabs3 .tab_right > .nav-tabs > li > a {
  border: none;
  margin-left: -1px;
  margin-left: 0;
  min-width: 80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs4 > .nav-tabs a::before {
  background: none !important;
}
.nav-tabs4 > .nav-tabs:after {
  bottom: 0;
}
.nav-tabs4 > .nav.nav-tabs .open > a {
  border-width: 0;
  border: none;
}
.nav-tabs4 .nav.nav-tabs .open > a:focus {
  border-width: 0;
  border: none;
}
.nav-tabs4 .tab_left > .nav-tabs {
  border-right: 1px solid #dddddd;
  float: left;
  margin-right: 0;
}
.nav-tabs4 .tab_left > .nav-tabs > li {
  float: none;
}
.nav-tabs4 .tab_left > .nav-tabs > li > a {
  border: none;
  margin-right: -1px;
  margin-right: 0;
  min-width: 80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs4 .tab_right > .tab-content {
  overflow: hidden;
}
.nav-tabs4 .tab_right > .nav-tabs {
  border-left: 1px solid #dddddd;
  float: right;
  margin-left: 0px;
}
.nav-tabs4 .tab_right > .nav-tabs > li {
  float: none;
}
.nav-tabs4 .tab_right > .nav-tabs > li > a {
  border: none;
  margin-left: -1px;
  margin-left: 0;
  min-width: 80px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}
.nav-tabs4 h3 {
  margin-top: 0 !important;
}
.nav-tabs4 > .nav-tabs > li {
  margin-bottom: -1px;
}
.nav-tabs4 > .nav-tabs > li:after {
  background: none !important;
}
.nav-tabs4 > .nav-tabs > li > a {
  border: none;
}
.nav-tabs4 > .nav.nav-tabs > li > a {
  background-color: #fff;
  color: #121212;
  padding: 10px;
  text-align: center;
}
.nav-tabs4 > .nav.nav-tabs.nav-dark > li > a {
  background-color: #353940;
  color: #dadada;
}
.nav-tabs4 > .nav.nav-tabs > li.active > a {
  background-color: #fff;
  border-left: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  border-top: 1px solid #dfdfdf !important;
  border: none;
  color: #121212;
}
.nav-tabs4 > .tab-content {
  background-color: #fff;
  border-top: 1px solid #dfdfdf;
  border: none;
  overflow: hidden;
  padding: 15px 0;
}
.nav-tabs4 > .nav-tabs.nav-dark .dropdown-menu {
  background-color: #353940;
}
.nav-tabs4 > .nav.nav-tabs.nav-dark .dropdown-menu a {
  color: #dadada;
}
.nav-tabs4 > .nav.nav-tabs.nav-dark .dropdown-menu li:hover {
  background: #373a41;
}
.nav-tabs4 > .nav.nav-tabs.nav-dark .dropdown-arrow-inverse {
  border-bottom-color: #353940 !important;
  border-top-color: #353940 !important;
}
.nav-tabs4 > .nav.nav-tabs.nav-dark .dropdown-inverse li > a:hover {
  background: #373a41;
}
.tabs-linetriangle .nav.nav-tabs {
  border-bottom: 1px solid #D8D8D8;
}
.tabs-linetriangle .nav.nav-tabs > li > a {
  background: none;
  color: #A2A4A6;
  font-family: 'Open Sans';
  font-size: 13px;
  font-weight: 600;
  padding: 12px 22px 16px;
  text-transform: uppercase;
}
.tabs-linetriangle .nav-tabs a {
  -webkit-transition: color .2s;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  overflow: visible;
  transition: color .2s;
}
.tabs-linetriangle .nav-tabs a::before {
  background: none;
}
.tabs-linetriangle .nav-tabs a span {
  display: block;
  font-size: .75em;
  font-size: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.tabs-linetriangle .nav-tabs li:hover a {
  color: #47494B;
}
.tabs-linetriangle .nav-tabs li.active a {
  color: #47494B;
}
.tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #F5F5F5;
  border-width: 10px;
  border: solid transparent;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -10px;
  pointer-events: none;
  position: absolute;
  top: 27px;
  top: 100%;
  width: 0;
}
.tabs-linetriangle .nav-tabs li.active a::before {
  border-bottom-color: #C8C8C8;
  border-width: 11px;
  border: solid transparent;
  content: '';
  height: 0;
  left: 50%;
  margin-left: -11px;
  pointer-events: none;
  position: absolute;
  top: 25px;
  top: 100%;
  width: 0;
}
.tabs-linetriangle .nav-tabs li.lines-3.active a::after {
  border-bottom-color: #F5F5F5;
  border-width: 10px;
  margin-left: -10px;
  top: 64px;
}
.tabs-linetriangle .nav-tabs li.lines-3.active a::before {
  border-bottom-color: #C8C8C8;
  border-width: 11px;
  margin-left: -11px;
  top: 62px;
}
.tabs-linetriangle .tab-content {
  background: none;
  border-top: none;
  padding: 15px 0;
  position: relative;
}
.tabs-linetriangle .select2-container .select2-choice {
  background: #fff !important;
}
.tabs-linetriangle .form-control {
  background: #fff !important;
}
.tabs-linetriangle .btn-default {
  background: #fff !important;
}
.tabs-linetriangle .btn-default:hover {
  background: #F8F8F8 !important;
}
.tabs-linetriangle .DTTT_container.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-left: 1px solid #E1E2E3 !important;
}
.DTTT_container.btn-group > .btn:last-child:not(:first-child) {
  border-left: 1px solid #E1E2E3 !important;
}
.panel .tabs-linetriangle .nav-tabs li.active a::after {
  border-bottom-color: #fff !important;
}
@media screen and (max-width: 58em) {
  .tabs-linetriangle .nav-tabs {
    font-size: 0.6em;
  }
}
.panel-accordion .panel-default {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  margin-bottom: 5px !important;
}
.panel-accordion h4 {
  margin-bottom: 0;
  margin-top: 0;
  width: 100%;
}
.panel-accordion .panel-default > .panel-heading {
  background-color: #ffffff;
  border-color: #ffffff;
  padding: 0;
}
.panel-accordion .panel-default > .panel-heading a {
  border: 1px solid #EDEDED;
  display: block;
  text-decoration: none !important;
}
.panel-accordion .panel {
  border-top: none;
  border: 1px solid #DFDFDF;
  border: none;
  box-shadow: none;
  margin-bottom: 4px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.panel-accordion .panel h4 > a:not(.collapsed) {
  background-color: #F3F3F3;
}
.panel-accordion .panel-collapse .panel-body {
  background: #F7F7F7;
  border: none !important;
}
.panel-accordion a {
  background-color: #ffffff;
  color: #121212;
  display: inline-block;
  padding: 15px;
  width: 100%;
}
.panel-accordion a:hover {
  text-decoration: none;
}
.panel-accordion a.collapsed {
  background-color: #ffffff;
  color: #121212;
}
.panel-accordion .collapse.in {
  margin-top: -1px;
}
.panel-accordion.dark-accordion a {
  background-color: #ffffff;
  color: #121212;
}
.panel-accordion.dark-accordion a.collapsed {
  background-color: #2B2E33;
  color: #dadada;
}
.panel-accordion.dark-accordion .panel-heading .panel-title > a.collapsed:after {
  color: #767B80;
}
.panel-heading .panel-title > a:after {
  color: #DFE5E9;
  content: "\f068";
  float: right;
  font-family: "line-icons" !important;
}
.panel-heading h4 > a {
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
  -ms-transition: all 0.4s ease-out;
  transition: all 0.4s ease-out;
}
.panel-heading h4 > a:after {
  color: #838F99;
  content: "-";
  float: right;
  font-family: 'Open Sans';
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 20px;
  margin-top: -1px;
  line-height: 18px;
  opacity: .5;
}
.panel-heading h4 > a.collapsed:after {
  color: #7E7E7E;
  content: "+";
  float: right;
  font-family: 'Open Sans';
  width: 24px;
  height: 24px;
  text-align: center;
  font-size: 20px;
  line-height: 22px;
  margin-top: -1px;
  opacity: .5;
}
.panel-heading h4 > a.collapsed:hover after {
  opacity: 1;
}
.dragged {
  left: -500px;
  opacity: 0.5;
  position: absolute;
  top: 0;
  z-index: 2000;
}
ul.vertical {
  margin: 0 0 9px 0;
  max-width: 600px;
  padding-left: 0;
}
ul.vertical li {
  background: #DFE5E9;
  color: #222222;
  display: block;
  font-weight: 600;
  margin: 5px;
  padding: 5px 9px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
ul.vertical li:hover {
  background: #d3dce1;
  cursor: pointer;
}
ul.vertical li.placeholder {
  border: none;
  margin: 0;
  padding: 0;
  position: relative;
}
ul.vertical li.placeholder:before {
  border-left-color: red;
  border-right: none;
  border: 5px solid transparent;
  content: "";
  height: 0;
  left: -5px;
  margin-top: -5px;
  position: absolute;
  top: -4px;
  width: 0;
}
ul i.icon-move {
  cursor: pointer;
}
ul li.highlight {
  background: #333333;
  color: #999999;
}
ul.nested_with_switch {
  border: 1px solid #eeeeee;
}
ul.nested_with_switch ul {
  border: 1px solid #eeeeee;
}
ul.nested_with_switch ul.active {
  border: 1px solid #333333;
}
ul.nested_with_switch li {
  cursor: pointer;
}
ul.nested_with_switch.active {
  border: 1px solid #333333;
}
ul.simple_with_animation li {
  cursor: pointer;
}
ul.default li {
  cursor: pointer;
}
.switch-container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 80px;
}
.navbar-sort-container {
  height: 200px;
}
ul.nav li {
  cursor: pointer;
}
ul.nav li a {
  cursor: pointer;
}
ul.nav .divider-vertical {
  cursor: default;
}
ul.nav li.dragged {
  background-color: #2c2c2c;
}
ul.nav li.placeholder {
  position: relative;
}
ul.nav li.placeholder:before {
  border-bottom: none;
  border-top-color: #00A2D9;
  border: 5px solid transparent;
  content: "";
  height: 0;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 0;
}
ul.nav ul.dropdown-menu li.placeholder:before {
  border-left-color: red;
  border-right: none;
  border: 5px solid transparent;
  left: 10px;
  margin-left: none;
  margin-top: -5px;
  top: 0;
}
.sortable_table tr {
  cursor: pointer;
}
.sortable_table tr.placeholder {
  border: none;
  height: 37px;
  margin: 0;
  padding: 0;
}
.sortable_table tr.placeholder td {
  background: #CBD5DB !important;
}
.sortable_table tr.placeholder:before {
  border-left-color: red;
  border-right: none;
  border: 5px solid transparent;
  height: 0;
  left: -5px;
  margin-top: -5px;
  position: absolute;
  width: 0;
}
.sortable_table tr.dragged td {
  background: #CBD5DB !important;
}
.sorted_head th {
  cursor: pointer;
}
.sorted_head th.placeholder {
  background: #CBD5DB;
  display: block;
  height: 0;
  margin: 0;
  padding: 0;
  position: relative;
  width: 0;
}
.sorted_head th.placeholder:before {
  border-bottom: none;
  border-top-color: red;
  border: 5px solid transparent;
  content: "";
  height: 0;
  margin-left: -5px;
  position: absolute;
  top: -6px;
  width: 0;
}
.ui-sortable-placeholder {
  background-color: #E5EAED !important;
  border: 1px dashed #b6bcbf;
  visibility: visible !important;
}
.dd {
  display: block;
  font-size: 13px;
  line-height: 20px;
  list-style: none;
  margin: 0;
  max-width: 600px;
  padding: 0;
  position: relative;
}
.dd-list {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item {
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  min-height: 20px;
  padding: 0;
  position: relative;
}
.dd-empty {
  -moz-box-sizing: border-box;
  ackground-color: #DFE1E4 !important;
  background-color: #e5e5e5;
  background-image: -moz-linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff), -moz-linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff);
  background-image: -webkit-linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff), -webkit-linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff);
  background-image: linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff), linear-gradient(45deg, #ffffff ffffffffffff 25%, transparent 25%, transparent 75%, #ffffff ffffffffffff 75%, #ffffff ffffffffffff);
  background-position: 0 0, 30px 30px;
  background-size: 60px 60px;
  border: 1px dashed #b6bcbf;
  border: 1px dashed #bbbbbb;
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  margin: 5px 0;
  min-height: 100px;
  min-height: 20px;
  min-height: 30px;
  padding: 0;
  position: relative;
  visibility: visible !important;
}
.dd-placeholder {
  -moz-box-sizing: border-box;
  ackground-color: #DFE1E4 !important;
  border: 1px dashed #b6bcbf;
  box-sizing: border-box;
  display: block;
  font-size: 13px;
  line-height: 20px;
  margin: 0;
  margin: 5px 0;
  min-height: 20px;
  min-height: 30px;
  padding: 0;
  position: relative;
  visibility: visible !important;
}
.dd-handle {
  -moz-box-sizing: border-box;
  background: #fff;
  border: 1px solid #E2E8EB;
  box-sizing: border-box;
  color: #4E5359;
  display: block;
  font-weight: 500;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.dd-handle:hover {
  background-color: #F2F5F7;
  cursor: pointer;
}
.dd-item > button {
  background: transparent;
  border: 0;
  cursor: pointer;
  display: block;
  float: left;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 1;
  margin: 5px 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  text-align: center;
  text-indent: 100%;
  white-space: nowrap;
  width: 25px;
}
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  text-align: center;
  text-indent: 0;
  width: 100%;
}
.rtl .dd-item > button {
  float: right;
}
.rtl .nestable ol li {
  margin-left: 0;
  margin-right: 20px;
}
.rtl .dd3-content {
  padding: 5px 40px 5px 10px;
}
.rtl .dd3-item > button {
  margin-left: 0;
  margin-right: 30px;
}
.rtl .dd3-handle {
  left: auto;
  right: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}
.nestable ol li {
  margin-left: 20px;
  margin-right: 0;
}
.dd-dragel {
  pointer-events: none;
  position: absolute;
  z-index: 9999;
}
.dd-dragel .dd-handle {
  -webkit-box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.nestable-lists {
  border: 0;
  clear: both;
  display: block;
  padding: 0;
  width: 100%;
}
#nestable-menu {
  margin: 20px 0;
  padding: 0;
}
#nestable-output {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.75em;
  height: 7em;
  line-height: 1.333333em;
  padding: 5px;
  width: 100%;
}
#nestable2-output {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.75em;
  height: 7em;
  line-height: 1.333333em;
  padding: 5px;
  width: 100%;
}
.dark .dd-handle {
  background: #d9e0e4;
  border: none;
  color: #6F7B8A;
}
.dark .dd-handle:hover {
  background: #d1dade;
  color: #505458;
}
.dark .dd-item > button:before {
  color: #8E9AA2;
}
.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}
.dd3-content {
  -moz-box-sizing: border-box;
  background: #fff;
  border: 1px solid #E2E8EB;
  box-sizing: border-box;
  color: #4E5359;
  display: block;
  font-weight: 500;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.dd3-content:hover {
  background: #fff;
  color: #6F7B8A;
}
.dd-handle ~ .dd3-content {
  padding: 5px 10px 5px 40px;
}
.dd-dragel > .dd3-item > .dd3-content {
  margin: 0;
}
.dd3-item > button {
  margin-left: 30px;
  margin-right: 0;
}
.dd3-handle {
  background: #fff;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  border: 1px solid #E2E8EB;
  color: #6F7B8A;
  cursor: pointer;
  font-weight: 600;
  left: 0;
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: auto;
  text-decoration: none;
  text-indent: 100%;
  top: 0;
  white-space: nowrap;
  width: 30px;
}
.dd3-handle:before {
  color: #505458;
  content: '≡';
  display: block;
  font-size: 20px;
  font-weight: normal;
  left: 0;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: 3px;
  width: 100%;
}
.dd3-handle:hover {
  background: #F2F5F7;
}
.nestable-dark .dd-handle {
  background: #353940;
  border: 1px solid #D3D3D3;
  color: #dadada;
}
.nestable-dark .dd-item > button {
  color: #dadada;
}
.nestable-dark .dd3-content {
  background: #353940;
  border: 1px solid #3E444F;
  color: #AEB2B8;
}
.nestable-dark .dd3-handle {
  border: 1px solid #3E444F;
}
.nestable-dark .dd3-handle:before {
  color: #DBDBDB;
}
th .div_checkbox {
  margin-top: -20px;
}
table .progress {
  margin-bottom: 0;
}
table .btn {
  margin-bottom: 0;
}
table tr.selected td {
  background-color: #f7fed5 !important;
  font-weight: 500;
}
table .btn.edit {
  margin-right: 0;
}
table > tbody > tr.selected:hover > td {
  background-color: #f4fec1 !important;
}
.table-hover > tbody > tr.selected:hover > th {
  background-color: #f4fec1 !important;
}
.table th {
  text-transform: uppercase;
}
.table th .select2-chosen {
  font-weight: normal;
  text-transform: none;
}
.table .fa.fa-plus-square-o {
  color: #8BA0B6;
}
.table-striped td:last-child {
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}
.table-hover thead th {
  border-bottom: 2px solid #F0F4F8;
}
.table-hover td {
  border-bottom: 2px solid #F0F4F8;
}
.table-bordered thead th {
  background-color: #F0F4F8;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 8px;
  vertical-align: middle;
}
.table > tbody > tr > td.details {
  background-color: #F9F9F9;
}
.table-hover > tbody > tr:hover > td {
  background-color: #F5F5F5;
  color: #000000;
}
.table-hover > tbody > tr:hover > td .form-control {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
.table-hover > tbody > tr:hover > th {
  background-color: #F5F5F5;
  color: #000000;
}
.table-hover > tbody > tr:hover > th .form-control {
  background-color: #ffffff;
  border: 1px solid #ffffff;
}
table.dataTable thead .sorting:before {
  color: #DDDDDD;
  content: "\f0dc";
  float: right;
  font-family: fontAwesome;
  padding-left: 10px;
}
table.dataTable thead .sorting_desc:before {
  color: #383838;
  content: "\f0dd";
  float: right;
  font-family: fontAwesome;
}
table.dataTable thead .sorting_asc:before {
  color: #383838;
  content: "\f0de";
  float: right;
  font-family: fontAwesome;
}
.table-red .pagination > .active > a,
.table-red .pagination > .active > span,
.table-red .pagination > .active > a:hover,
.table-red .pagination > .active > span:hover,
.table-red .pagination > .active > a:focus,
.table-red .pagination > .active > span:focus {
  color: #C75757;
}
.table-red .pagination > .active > span:focus {
  color: #C75757;
}
.table-red div.dataTables_info {
  color: #D64545;
}
#main-content .table a {
  margin-bottom: 5px;
}
div.dataTables_info {
  color: #8BA0B6;
  font-size: 12px;
  padding-top: 8px;
}
.dataTables_filter {
  float: left;
  float: right;
}
.filter-left .dataTables_filter {
  float: left;
}
.DTTT_container {
  float: right;
  position: relative;
}
.DTTT_container .btn {
  height: 35px;
  margin-left: 10px;
  overflow: hidden;
  position: relative;
}
.DTTT_container.btn-group > .btn span {
  text-align: center;
}
.DTTT_container.btn-group > .btn span i {
  padding-right: 0 !important;
}
.DTTT_container.btn-group > .btn:not(.first-child):not(:last-child):not(.dropdown-toggle) {
  border-left: 1px solid #D2D5D8 !important;
  height: 35px;
  overflow: hidden;
  padding-top: 10px;
}
.DTTT_container.btn-group > .btn:last-child:not(:first-child) {
  border-left: 1px solid #D2D5D8 !important;
  height: 35px;
  overflow: hidden;
  padding-top: 10px;
}
.DTTT_container .icon-printer,
.DTTT_container .fa-file-pdf-o,
.DTTT_container .fa-file-excel-o,
.DTTT_container .fa-table {
  font-size: 16px;
  text-align: center;
}
.dataTable .fa-plus-square-o {
  cursor: pointer;
}
.dataTable .sorting_asc {
  cursor: pointer;
  text-align: left;
}
.dataTable .sorting_desc {
  cursor: pointer;
  text-align: left;
}
.dataTable .sorting {
  cursor: pointer;
  text-align: left;
}
.dataTable thead th input[type=text] {
  background: url(../images/various/icon-search-gray.png) no-repeat 12px;
  display: block;
  font-weight: 400;
  padding-left: 32px;
  float: left;
}
.dataTable tfoot th input[type=text] {
  background: url(../images/various/icon-search-gray.png) no-repeat 12px;
  display: block;
  font-weight: 400;
  padding-left: 32px;
}
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success > td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th {
  background-color: #c5eadd;
}
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger > td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th {
  background-color: #f2dede;
}
.div-table .col-xs-1,
.div-table .col-sm-1,
.div-table .col-md-1,
.div-table .col-lg-1,
.div-table .col-xs-2,
.div-table .col-sm-2,
.div-table .col-md-2,
.div-table .col-lg-2,
.div-table .col-xs-3,
.div-table .col-sm-3,
.div-table .col-md-3,
.div-table .col-lg-3,
.div-table .col-xs-4,
.div-table .col-sm-4,
.div-table .col-md-4,
.div-table .col-lg-4,
.div-table .col-xs-5,
.div-table .col-sm-5,
.div-table .col-md-5,
.div-table .col-lg-5,
.div-table .col-xs-6,
.div-table .col-sm-6,
.div-table .col-md-6,
.div-table .col-lg-6,
.div-table .col-xs-7,
.div-table .col-sm-7,
.div-table .col-md-7,
.div-table .col-lg-7,
.div-table .col-xs-8,
.div-table .col-sm-8,
.div-table .col-md-8,
.div-table .col-lg-8,
.div-table .col-xs-9,
.div-table .col-sm-9,
.div-table .col-md-9,
.div-table .col-lg-9,
.div-table .col-xs-10,
.div-table .col-sm-10,
.div-table .col-md-10,
.div-table .col-lg-10,
.div-table .col-xs-11,
.div-table .col-sm-11,
.div-table .col-md-11,
.div-table .col-lg-11,
.div-table .col-xs-12,
.div-table .col-sm-12,
.div-table .col-md-12,
.div-table .col-lg-12 {
  padding: 0 !important;
}
.div-table[class^="col-"]::first-child {
  padding-left: 15px !important;
}
.div-table[class^="col-"]::last-child {
  padding-right: 15px !important;
}
.div-table p {
  padding: 5px 10px;
}
.div-t-head {
  background-color: #F4F4F4 !important;
  color: #5B5B5B !important;
}
.div-t-head p {
  margin-bottom: 0;
}
.div-td {
  border-bottom: 1px solid #EFEFEF;
}
.div-td p {
  margin-bottom: 0;
}
.force-table-responsive {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
  border: 1px solid #E9E9E9;
  border: none;
  margin-bottom: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 10px 5px;
  width: 100%;
}
.force-table-responsive .force-table-responsive {
  overflow-x: hidden;
}
.no-collapse {
  border-collapse: separate;
}
.dataTables_paginate .pagination {
  float: right;
  margin-top: 0;
}
@media (max-width: 1199px) {
  .div-table [class^="col-"] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media (max-width: 991px) {
  div.dataTables_info {
    display: block;
    float: none;
    margin-bottom: 8px;
    margin-top: 0;
    text-align: center;
  }
  .dataTables_paginate {
    float: none !important;
    text-align: center;
  }
  .dataTables_paginate .pagination {
    float: none;
  }
  div.dataTables_filter input {
    width: auto !important;
  }
}
@media (max-width: 767px) {
  .table-responsive {
    padding-top: 10px;
  }
}
@media screen and (max-width: 580px) {
  .dataTables_filter {
    float: none;
    width: 100%;
  }
  div.dataTables_filter input {
    width: 100% !important;
  }
  div.dataTables_filter label {
    width: 97% !important;
  }
  .DTTT_container.btn-group {
    width: 97%;
  }
  .DTTT_container.btn-group a {
    width: 25%;
  }
}
@media (max-width: 400px) {
  div.dataTables_filter input {
    width: 100px !important;
  }
}
.sp-container {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: inline;
  display: inline-block;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 9999994;
  zoom: 1;
}
.sp-container * {
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.sp-container.sp-flat {
  position: relative;
}
.sp-top {
  display: inline-block;
  position: relative;
  width: 100%;
}
.sp-top-inner {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-color {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 20%;
  top: 0;
}
.sp-hue {
  bottom: 0;
  height: 100%;
  left: 84%;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-clear-enabled .sp-hue {
  height: 77.5%;
  top: 33px;
}
.sp-clear-enabled .sp-clear {
  bottom: 0;
  display: block;
  height: 28px;
  left: 84%;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-fill {
  padding-top: 80%;
}
.sp-sat {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-val {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-alpha-enabled .sp-top {
  margin-bottom: 18px;
}
.sp-alpha-enabled .sp-alpha {
  display: block;
}
.sp-alpha-handle {
  background: white;
  border: 1px solid black;
  bottom: -4px;
  cursor: pointer;
  left: 50%;
  opacity: .8;
  position: absolute;
  top: -4px;
  width: 6px;
}
.sp-alpha {
  bottom: -14px;
  display: none;
  height: 8px;
  left: 0;
  position: absolute;
  right: 0;
}
.sp-alpha-inner {
  border: solid 1px #333333;
}
.sp-clear {
  display: none;
}
.sp-clear.sp-clear-display {
  background-position: center;
}
.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
  -moz-user-select: -moz-none;
  -o-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.sp-container.sp-input-disabled .sp-input-container {
  display: none;
}
.sp-container.sp-buttons-disabled .sp-button-container {
  display: none;
}
.sp-palette-only .sp-picker-container {
  display: none;
}
.sp-palette-disabled .sp-palette-container {
  display: none;
}
.sp-initial-disabled .sp-initial {
  display: none;
}
.sp-sat {
  background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#ffffff), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(left, #ffffff, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to right, #ffffff, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr='#FFFFFFFF', endColorstr='#00CC9A81');
}
.sp-val {
  background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
  background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -moz-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -o-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: -ms-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
  background-image: linear-gradient(to top, #000000, rgba(204, 154, 129, 0));
  -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00CC9A81', endColorstr='#FF000000');
}
.sp-hue {
  background: -moz-linear-gradient(top, #ff0000 0, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -ms-linear-gradient(top, #ff0000 0, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -o-linear-gradient(top, #ff0000 0, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
  background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
  background: -webkit-linear-gradient(top, #ff0000 0, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
}
.sp-1 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='@color0', endColorstr='#ffff00');
  height: 17%;
}
.sp-2 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffff00', endColorstr='#00ff00');
  height: 16%;
}
.sp-3 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff00', endColorstr='#00ffff');
  height: 17%;
}
.sp-4 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffff', endColorstr='#0000ff');
  height: 17%;
}
.sp-5 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0000ff', endColorstr='#ff00ff');
  height: 16%;
}
.sp-6 {
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff00ff', endColorstr='@color0');
  height: 17%;
}
.sp-hidden {
  display: none !important;
}
.sp-cf {
  zoom: 1;
}
.sp-cf::before {
  content: "";
  display: table;
}
.sp-cf::after {
  clear: both;
  content: "";
  display: table;
}
.sp-dragger {
  background: #000000;
  border-radius: 5px;
  border: 1px solid #ffffff;
  cursor: pointer;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}
.sp-slider {
  background: #31333C;
  border: 1px solid #31333C;
  cursor: pointer;
  height: 3px;
  left: -1px;
  opacity: .8;
  position: absolute;
  right: -1px;
  top: 0;
}
.sp-container {
  background-color: #ffffff;
  border-radius: 0;
  border: solid 1px #D7D7D7;
  padding: 0;
}
.sp-container input {
  background: #ffffff;
  border: 0;
}
.sp-container,
.sp-container button,
.sp-container input,
.sp-color,
.sp-hue,
.sp-clear {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.sp-top {
  margin-bottom: 3px;
}
.sp-color {
  border: none;
}
.sp-hue {
  border: none;
}
.sp-clear {
  border: none;
}
.sp-input-container {
  float: right;
  margin-bottom: 4px;
  width: 100px;
}
.sp-initial-disabled .sp-input-container {
  width: 100%;
}
.sp-input {
  background: transparent;
  border-radius: 3px;
  border: 1px inset;
  color: #222222;
  font-size: 12px !important;
  margin: 0;
  padding: 4px 5px;
  width: 100%;
}
.sp-input:focus {
  border: 1px solid orange;
}
.sp-input.sp-validation-error {
  background: #ffdddd;
  border: 1px solid red;
}
.sp-picker-container {
  border-left: solid 1px #ffffff;
  float: left;
  padding: 10px;
  margin-bottom: -290px;
  padding-bottom: 300px;
  position: relative;
}
.sp-palette-container {
  border-right: solid 1px #cccccc;
  float: left;
  margin-bottom: -290px;
  padding: 10px;
  padding-bottom: 300px;
  position: relative;
}
.sp-palette .sp-thumb-el {
  border: solid 2px transparent;
  cursor: pointer;
  display: block;
  float: left;
  height: 15px;
  margin: 3px;
  position: relative;
  width: 24px;
}
.sp-palette .sp-thumb-el:hover {
  border-color: orange;
}
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: orange;
}
.sp-thumb-el {
  position: relative;
}
.sp-initial {
  border: solid 1px #333333;
  float: left;
}
.sp-initial span {
  border: 0;
  display: block;
  float: left;
  height: 25px;
  margin: 0;
  width: 30px;
}
.sp-initial .sp-clear-display {
  background-position: center;
}
.sp-button-container {
  float: right;
}
.sp-replacer {
  background: #C9C9C9;
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  height: 35px;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: 55px;
}
.sp-replacer:hover {
  border-color: #f0c49b;
  color: #111111;
}
.sp-replacer.sp-active {
  border-color: #f0c49b;
  color: #111111;
}
.sp-replacer.sp-disabled {
  border-color: silver;
  color: silver;
  cursor: default;
}
.sp-dd {
  display: none;
  float: left;
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  padding: 2px 0;
}
.sp-preview {
  background-image: url(data:image/png;
  base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  float: left;
  height: 100%;
  margin-right: 5px;
  position: relative;
  width: 100%;
  z-index: 0;
}
.sp-palette {
  max-width: 220px;
  width: 220px;
}
.sp-palette .sp-thumb-el {
  border: solid 1px #d0d0d0;
  height: 16px;
  margin: 2px 1px;
  width: 16px;
}
.sp-palette span:hover {
  border-color: #000000;
}
.sp-palette span.sp-thumb-active {
  border-color: #000000;
}
.sp-palette .sp-thumb-inner {
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
  background-image: url(data:image/png;
      base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=);
}
.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
  background-image: url(data:image/png;
      base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=);
}
.sp-container {
  padding-bottom: 0;
}
.sp-cancel {
  color: #ffffff !important;
  font-size: 11px;
  margin-bottom: 0 !important;
  margin-right: 5px;
  margin: 0;
  padding: 2px;
  text-decoration: none;
  vertical-align: middle;
}
.sp-alpha {
  background-image: url(data:image/png;
  base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  position: relative;
}
.sp-thumb-el {
  background-image: url(data:image/png;
  base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==);
  position: relative;
}
.sp-preview-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-alpha-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-thumb-inner {
  bottom: 0;
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
.sp-clear-display {
  background-image: url(data:image/gif;
  background-position:center;
  background-repeat:no-repeat;
  base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==);
}
.builder .sp-picker-container .btn {
  display: inline-block;
  float: left;
  width: auto;
}
@media (max-device-width: 480px) {
  .sp-color {
    right: 40%;
  }
  .sp-hue {
    left: 63%;
  }
  .sp-fill {
    padding-top: 60%;
  }
}
.note-editor {
  border: none;
  position: relative;
}
.note-editor .note-dropzone {
  background-color: #ffffff;
  border: 2px dashed #87cefa;
  color: #87cefa;
  display: none;
  opacity: .95;
  pointer-event: none;
  position: absolute;
  z-index: 1;
}
.note-editor .note-dropzone .note-dropzone-message {
  display: table-cell;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  vertical-align: middle;
}
.note-editor .note-dropzone.hover {
  border: 2px dashed #098ddf;
  color: #098ddf;
}
.note-editor .note-statusbar {
  background-color: #f5f5f5;
}
.note-editor .note-statusbar .note-resizebar {
  border-top: 1px solid #ECECEC;
  cursor: ns-resize;
  height: 8px;
  width: 100%;
}
.note-editor .note-statusbar .note-resizebar .note-icon-bar {
  border-top: 1px solid #a9a9a9;
  margin: 1px auto;
  width: 20px;
}
.note-editor .note-editable[contenteditable=true]:empty:not(:focus):before {
  color: #a9a9a9;
  content: attr(data-placeholder);
}
.note-editor .note-editable {
  border: 1px solid #f5f5f5;
  outline: 0;
  overflow: auto;
  padding: 10px;
}
.note-editor .note-editable[contenteditable="false"] {
  background-color: #e5e5e5;
}
.note-editor .note-codable {
  -moz-border-radius: 0;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-border-radius: 0;
  -webkit-box-sizing: border-box;
  background-color: #222222;
  border-radius: 0;
  border: 0;
  box-shadow: none;
  box-sizing: border-box;
  color: #cccccc;
  display: none;
  font-family: Menlo, Monaco, monospace, sans-serif;
  font-size: 14px;
  margin-bottom: 0;
  padding: 10px;
  resize: none;
  width: 100%;
}
.note-editor .btn {
  height: 37px;
  margin-bottom: 0 !important;
}
.note-editor.dragover .note-dropzone {
  display: table;
}
.note-editor.fullscreen {
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
}
.note-editor.fullscreen .note-editable {
  background-color: #ffffff;
}
.note-editor.fullscreen .note-resizebar {
  display: none;
}
.note-editor.codeview .note-editable {
  display: none;
}
.note-editor.codeview .note-codable {
  display: block;
}
.note-air-editor {
  outline: 0;
}
.note-popover .popover {
  max-width: none;
}
.note-popover .popover .popover-content {
  background: #f5f5f5;
  margin: 0;
  padding: 0;
}
.note-popover .popover .popover-content a {
  border-right: 1px solid rgba(12, 12, 12, 0.1) !important;
  display: inline-block;
  max-width: 200px;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 45px;
}
.note-popover .popover .popover-content .btn-group .note-table {
  min-width: 0;
  padding: 5px;
}
.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker {
  font-size: 18px;
}
.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  cursor: pointer;
  height: 10em;
  position: absolute!important;
  width: 10em;
  z-index: 3;
}
.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  background: url(data:image/png; base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat;
  height: 5em;
  position: relative!important;
  width: 5em;
  z-index: 1;
}
.note-popover .popover .popover-content .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat;
  height: 1em;
  position: absolute!important;
  width: 1em;
  z-index: 2;
}
.note-popover .popover .popover-content .note-color .dropdown-toggle {
  padding-left: 5px;
  width: 20px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu {
  min-width: 340px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group {
  margin: 0;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-palette-title {
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  margin: 2px 7px;
  text-align: center;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  margin: 3px;
  padding: 0 3px;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eeeeee;
}
.note-popover .popover .popover-content .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px;
}
.note-popover .popover .popover-content .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}
.note-popover .popover .popover-content .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}
.note-popover .popover .popover-content .dropdown-menu {
  min-width: 90px;
}
.note-popover .popover .popover-content .dropdown-menu li a i {
  color: #00bfff;
  visibility: hidden;
}
.note-popover .popover .popover-content .dropdown-menu li a.checked i {
  visibility: visible;
}
.note-popover .popover .popover-content .dropdown-menu.right {
  left: auto;
  right: 0;
}
.note-popover .popover .popover-content .dropdown-menu.right::before {
  left: auto!important;
  right: 9px;
}
.note-popover .popover .popover-content .dropdown-menu.right::after {
  left: auto!important;
  right: 10px;
}
.note-popover .popover .popover-content .note-fontsize-10 {
  font-size: 10px;
}
.note-popover .popover .popover-content .note-color-palette {
  line-height: 1;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn {
  border: 1px solid #ffffff;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
}
.note-popover .popover .popover-content .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000000;
}
.note-popover .popover .arrow {
  left: 20px;
}
.note-popover .popover .popover-content > .btn-group {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 0;
}
.note-popover .btn {
  margin-bottom: 0 !important;
}
.note-popover .fa-magic {
  padding-top: 6px;
}
.note-toolbar {
  background: #f5f5f5;
  margin: 0;
  padding: 0;
}
.note-toolbar .btn-group .note-table {
  min-width: 0;
  padding: 5px;
}
.note-toolbar .btn-group .note-table .note-dimension-picker {
  font-size: 18px;
}
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-mousecatcher {
  cursor: pointer;
  height: 10em;
  position: absolute!important;
  width: 10em;
  z-index: 3;
}
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-unhighlighted {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat;
  height: 5em;
  position: relative!important;
  width: 5em;
  z-index: 1;
}
.note-toolbar .btn-group .note-table .note-dimension-picker .note-dimension-picker-highlighted {
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIjd6vvD2f9LKLW+AAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKwNDEVT0AAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC) repeat;
  height: 1em;
  position: absolute!important;
  width: 1em;
  z-index: 2;
}
.note-toolbar .note-color .dropdown-toggle {
  padding-left: 5px;
  width: 20px;
}
.note-toolbar .note-color .dropdown-menu {
  min-width: 340px;
}
.note-toolbar .note-color .dropdown-menu .btn-group {
  margin: 0;
}
.note-toolbar .note-color .dropdown-menu .btn-group:first-child {
  margin: 0 5px;
}
.note-toolbar .note-color .dropdown-menu .btn-group .note-palette-title {
  border-bottom: 1px solid #eeeeee;
  font-size: 12px;
  margin: 2px 7px;
  text-align: center;
}
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 11px;
  margin: 3px;
  padding: 0 3px;
}
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-reset:hover {
  background: #eeeeee;
}
.note-toolbar .note-color .dropdown-menu .btn-group .note-color-row {
  height: 20px;
}
.note-toolbar .note-para .dropdown-menu {
  min-width: 216px;
  padding: 5px;
}
.note-toolbar .note-para .dropdown-menu > div:first-child {
  margin-right: 5px;
}
.note-toolbar .dropdown-menu {
  min-width: 90px;
}
.note-toolbar .dropdown-menu li a i {
  color: #00bfff;
  visibility: hidden;
}
.note-toolbar .dropdown-menu li a.checked i {
  visibility: visible;
}
.note-toolbar .dropdown-menu.right {
  left: auto;
  right: 0;
}
.note-toolbar .dropdown-menu.right::before {
  left: auto!important;
  right: 9px;
}
.note-toolbar .dropdown-menu.right::after {
  left: auto!important;
  right: 10px;
}
.note-toolbar .note-fontsize-10 {
  font-size: 10px;
}
.note-toolbar .note-color-palette {
  line-height: 1;
}
.note-toolbar .note-color-palette div .note-color-btn {
  border: 1px solid #ffffff;
  height: 20px;
  margin: 0;
  padding: 0;
  width: 20px;
}
.note-toolbar .note-color-palette div .note-color-btn:hover {
  border: 1px solid #000000;
}
.note-toolbar > .btn-group {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 0;
}
.note-popover .popover .popover-content .note-style h1,
.note-toolbar .note-style h1,
.note-popover .popover .popover-content .note-style h2,
.note-toolbar .note-style h2,
.note-popover .popover .popover-content .note-style h3,
.note-toolbar .note-style h3,
.note-popover .popover .popover-content .note-style h4,
.note-toolbar .note-style h4,
.note-popover .popover .popover-content .note-style h5,
.note-toolbar .note-style h5,
.note-popover .popover .popover-content .note-style h6,
.note-toolbar .note-style h6,
.note-popover .popover .popover-content .note-style blockquote,
.note-toolbar .note-style blockquote {
  margin: 0;
}
.note-dialog > div {
  display: none;
}
.note-dialog .note-modal-form {
  margin: 0;
}
.note-dialog .note-image-dialog .note-dropzone {
  border: 4px dashed lightgray;
  color: lightgray;
  font-size: 30px;
  line-height: 4;
  margin-bottom: 10px;
  min-height: 100px;
  text-align: center;
}
.note-dialog .note-help-dialog {
  -khtml-opacity: .9;
  -moz-opacity: .9;
  -ms-filter: alpha(opacity=90);
  -webkit-opacity: .9;
  background-color: #222222!important;
  background: transparent;
  border: 0;
  color: #cccccc;
  filter: alpha(opacity=90);
  font-size: 12px;
  opacity: .9;
}
.note-dialog .note-help-dialog .modal-content {
  -moz-border-radius: 5px;
  -moz-box-shadow: none;
  -webkit-border-radius: 5px;
  -webkit-box-shadow: none;
  background: transparent;
  border-radius: 5px;
  border: 1px solid #ffffff;
  box-shadow: none;
}
.note-dialog .note-help-dialog a {
  color: #ffffff;
  font-size: 12px;
}
.note-dialog .note-help-dialog .title {
  border-bottom: #ffffff 1px solid;
  color: #ffffff;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 10px;
  padding-bottom: 5px;
}
.note-dialog .note-help-dialog .modal-close {
  color: #dddd00;
  cursor: pointer;
  font-size: 14px;
}
.note-dialog .note-help-dialog .text-center {
  margin: 10px 0 0;
}
.note-dialog .note-help-dialog .note-shortcut {
  padding-bottom: 8px;
  padding-top: 8px;
}
.note-dialog .note-help-dialog .note-shortcut-row {
  margin-left: -5px;
  margin-right: -5px;
}
.note-dialog .note-help-dialog .note-shortcut-col {
  padding-left: 5px;
  padding-right: 5px;
}
.note-dialog .note-help-dialog .note-shortcut-title {
  color: #dddd00;
  font-size: 13px;
  font-weight: 700;
}
.note-dialog .note-help-dialog .note-shortcut-key {
  color: #dddd00;
  font-family: "Courier New";
  text-align: right;
}
.note-handle .note-control-selection {
  border: 1px solid #000000;
  display: none;
  position: absolute;
}
.note-handle .note-control-selection .note-control-selection-bg {
  -khtml-opacity: .3;
  -moz-opacity: .3;
  -ms-filter: alpha(opacity=30);
  -webkit-opacity: .3;
  background-color: #000000;
  filter: alpha(opacity=30);
  height: 100%;
  opacity: .3;
  width: 100%;
}
.note-handle .note-control-selection .note-control-handle {
  border: 1px solid #000000;
  height: 7px;
  width: 7px;
}
.note-handle .note-control-selection .note-control-holder {
  border: 1px solid #000000;
  height: 7px;
  width: 7px;
}
.note-handle .note-control-selection .note-control-sizing {
  background-color: #ffffff;
  border: 1px solid #000000;
  height: 7px;
  width: 7px;
}
.note-handle .note-control-selection .note-control-nw {
  border-bottom: 0;
  border-right: 0;
  left: -5px;
  top: -5px;
}
.note-handle .note-control-selection .note-control-ne {
  border-bottom: 0;
  border-left: none;
  right: -5px;
  top: -5px;
}
.note-handle .note-control-selection .note-control-sw {
  border-right: 0;
  border-top: 0;
  bottom: -5px;
  left: -5px;
}
.note-handle .note-control-selection .note-control-se {
  bottom: -5px;
  cursor: se-resize;
  right: -5px;
}
.note-handle .note-control-selection .note-control-selection-info {
  -khtml-opacity: .7;
  -moz-border-radius: 5px;
  -moz-opacity: .7;
  -ms-filter: alpha(opacity=70);
  -webkit-border-radius: 5px;
  -webkit-opacity: .7;
  background-color: #000000;
  border-radius: 5px;
  bottom: 0;
  color: #ffffff;
  filter: alpha(opacity=70);
  font-size: 12px;
  margin: 5px;
  opacity: .7;
  padding: 5px;
  right: 0;
}
.note-handle .note-control-selection > div {
  position: absolute;
}
.note-style {
  height: 37px;
  overflow: visible;
}
.note-style blockquote {
  padding-bottom: 0;
  padding-top: 0;
}
.note-style i {
  font-size: 15px !important;
}
.note-style h1,
.note-style h2,
.note-style h3,
.note-style h4,
.note-style h5 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.note-air-popover.popover {
  background: #f5f5f5;
}
.note-air-popover.popover.bottom > .arrow:after {
  border-bottom-color: #f5f5f5;
}
.note-link-popover.popover.bottom > .arrow:after {
  border-bottom-color: #f5f5f5;
}
.cursor-pointer {
  cursor: pointer;
}
code {
  padding: 2px 4px 2px 4px;
  font-size: 90%;
  color: #575757;
  background-color: #EEF4FF;
  white-space: nowrap;
  border-radius: 4px;
}
.line-separator {
  border-right: 1px solid #DBE2E7;
}
img.img-left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}
img.img-right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}
.hide {
  opacity: 0;
}
a,
a:focus,
a:hover,
a:active {
  outline: 0;
}
li {
  line-height: 25px;
}
img.img-left {
  border: 1px solid #cccccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}
img.img-right {
  border: 1px solid #cccccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}
textarea {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  resize: none;
}
ul,
li,
input,
.input-group,
p,
label,
div,
section,
textarea {
  float: none;
  text-align: left;
}
.z2 {
  z-index: 2;
}
.ov-hidden {
  overflow: hidden;
}
.label-dark {
  background-color: rgba(0, 0, 0, 0.6);
  padding: .4em .8em .5em;
}
label.required:after {
  content: '*';
  color: #FF5757;
  margin-left: 2px;
}
.dropdown-menu > li > a.no-option {
  padding: 0;
  height: 0;
}
button .fa-trash,
.trash-link {
  font-size: 20px;
  display: inline-block;
  color: #C75757 !important;
}
button .fa-trash:hover,
.trash-link:hover {
  color: #A33636;
}
.btn.btn-link {
  padding: 4px !important;
  border: none !important
    ;
  background: rgba(0, 0, 0, 0) none !important;
}
.btn.btn-link i {
  padding-right: 0 !important;
}
.nav-pills > li > a {
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.nav-tabs > li > a {
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0;
}
.dropdown-menu {
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 5px;
  -moz-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03);
  -webkit-box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03);
  box-shadow: 3px 3px 0 rgba(12, 12, 12, 0.03);
}
.dropdown-menu li a {
  -moz-transition: all 0.2s ease-out 0;
  -webkit-transition: all 0.2s ease-out 0;
  transition: all 0.2s ease-out 0;
}
.dropdown-menu > li > a {
  padding: 7px 10px;
  color: #666666;
}
.dropdown-menu > li > a:hover {
  background-color: #e7e7e7;
  color: #333333;
}
.dropdown-menu li a i {
  padding-right: 8px;
  padding-left: 0;
}
.typed-cursor {
  opacity: 1;
  -webkit-animation: blink 0.7s infinite;
  -moz-animation: blink 0.7s infinite;
  animation: blink 0.7s infinite;
}
input.parsley-success,
select.parsley-success,
textarea.parsley-success {
  color: #468847;
  background-color: #DFF0D8;
  border: 1px solid #D6E9C6;
}
input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  color: #B94A48;
  background-color: #F2DEDE;
  border: 1px solid #EED3D7;
}
.parsley-errors-list {
  margin: 8px 0 3px 0;
  padding: 0;
  list-style-type: none;
  font-size: 0.9em;
  line-height: 0.9em;
  opacity: 0;
  -moz-opacity: 0;
  -webkit-opacity: 0;
  color: #AA0000;
  transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  -ms-transition: all 0.3s ease-in;
  -moz-transition: all 0.3s ease-in;
  -webkit-transition: all 0.3s ease-in;
}
.parsley-errors-list.filled {
  opacity: 1;
}
.jstree-default.jstree-rtl .jstree-node {
  background: none !important;
  background-image: none !important;
}
.jstree-default.jstree-rtl .jstree-closed > .jstree-ocl {
  -ms-transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}
.rtl .jstree-default .jstree-ocl {
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rtl ul,
.rtl li,
.rtl input,
.rtl p,
.rtl label,
.rtl div,
.rtl section,
.rtl textarea {
  text-align: right;
}
.rtl .fileinput-new.input-group .btn-file {
  border-radius: 4px 0 0 4px;
}
.rtl .fileinput-new .input-group .btn-file {
  border-radius: 4px 0 0 4px;
}
.rtl .ui-timepicker-div dl dt {
  clear: right;
  float: right;
  padding: 5px 5px 0 0;
  text-align: right;
}
.rtl .ui-timepicker-div dl dd {
  margin: 0 40% 20px 10px;
  text-align: right;
}
.rtl .ui-timepicker-div .ui-slider-horizontal .ui-slider-handle {
  margin-left: 0;
  margin-right: -0.6em;
}
.rtl .ui-timepicker-div .ui-slider .ui-slider-handle:before {
  left: auto;
  right: 4px;
}
.rtl .ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: right;
}
.rtl .ui-datepicker .ui-datepicker-buttonpane button {
  float: left;
}
.rtl .ui-datepicker-buttonpane {
  clear: both;
}
.rtl .ui-datepicker-buttonpane:before {
  content: " ";
  display: table;
}
.rtl .ui-datepicker-buttonpane:after {
  clear: both;
  content: " ";
  display: table;
}
.rtl .main-content .page-content .ui-datepicker i {
  float: none !important;
  padding-left: 6px !important;
  padding-right: 6px !important;
}
.rtl .main-content .page-content .panel .panel-content i {
  float: right;
  padding-left: 6px;
  padding-right: 0;
  padding-top: 2px;
}
.rtl .main-content .page-content .panel .panel-content .inside-tooltip i {
  padding-top: 0;
}
.rtl .main-content .page-content table i {
  padding-left: 0 !important;
}
.rtl .main-content .page-content table .btn {
  height: 26px;
}
.rtl .main-content .page-content table .btn.edit i {
  margin-top: 2px;
}
.rtl .main-content .page-content .pagination i {
  padding-top: 0 !important;
}
.rtl .media-left {
  padding-left: 10px;
  padding-right: 0;
}
.rtl .media > .pull-left {
  padding-left: 10px;
  padding-right: 0;
}
.rtl .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  -moz-border-radius: 4px 0 0 0;
  -webkit-border-radius: 4px 0 0 0;
  border-radius: 4px 0 0 0;
}
.rtl .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  -moz-border-radius: 0 0 0 4px;
  -webkit-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.rtl .input-group-btn-vertical i {
  padding-left: 0 !important;
  padding-top: 0 !important;
}
.rtl .bootstrap-tagsinput .tag {
  display: inline-block;
  position: relative;
}
.rtl .bootstrap-tagsinput .tag[data-role="remove"] {
  float: left;
  margin-left: 0;
  margin-right: 8px;
}
.rtl .switch-input {
  left: auto;
  right: 0;
}
.rtl .switch-input:checked ~ .switch-handle {
  left: auto;
  right: 40px;
}
.rtl .switch-label:before {
  content: attr(data-off);
  left: 7px;
  right: auto;
}
.rtl .switch-label:after {
  content: attr(data-on);
  left: 0;
  right: 11px;
}
.rtl .switch-handle {
  -moz-transition: right 0.15s ease-out;
  -o-transition: right 0.15s ease-out;
  -webkit-transition: right 0.15s ease-out;
  left: auto;
  right: 4px;
  transition: right 0.15s ease-out;
}
.rtl .switch-handle:before {
  right: 50%;
}
.rtl .form-right .control-label {
  text-align: left !important;
}
.rtl .append-icon i {
  left: 0;
  right: auto;
}
.rtl .ios-switch input[type="checkbox"]:checked + div {
  padding-left: 0;
  padding-right: 2em;
  text-align: right;
}
.rtl .ios-switch input[type="checkbox"] + div:before {
  float: right;
  text-align: right;
}
.rtl .ios-switch input[type="checkbox"] + div:after {
  float: right;
  text-align: right;
}
.rtl .onoffswitch {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}
.rtl .onoffswitch .onoffswitch-inner {
  margin-left: 0;
  margin-right: -100%;
}
.rtl .onoffswitch .onoffswitch-inner .onoffswitch-active {
  padding-left: 0;
  padding-right: 15px;
}
.rtl .onoffswitch .onoffswitch-inner .onoffswitch-inactive {
  padding-left: 15px;
  padding-right: 0;
  text-align: left;
}
.rtl .onoffswitch .onoffswitch-inner > span {
  float: right;
}
.rtl .onoffswitch .onoffswitch-active .onoffswitch-switch {
  left: auto;
  right: 0;
}
.rtl .onoffswitch .onoffswitch-active .onoffswitch-switch:before {
  border-color: rgba(0, 0, 0, 0) #34a7c1 #34a7c1 rgba(0, 0, 0, 0);
  left: auto;
  right: 40px;
}
.rtl .onoffswitch .onoffswitch-inactive .onoffswitch-switch {
  left: 0;
  right: auto;
}
.rtl .onoffswitch .onoffswitch-inactive .onoffswitch-switch:before {
  border-color: #a1a1a1 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #a1a1a1;
  left: 40px;
  right: auto;
}
.rtl .onoffswitch .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: auto;
  margin-right: 0;
}
.rtl .onoffswitch2 {
  float: right;
  margin-left: 20px;
  margin-right: 0;
}
.rtl .onoffswitch2 .onoffswitch-inner {
  margin-left: auto;
  margin-right: -100%;
}
.rtl .onoffswitch2 .onoffswitch-inner:before {
  float: right;
  padding-left: 0;
  padding-left: 10px;
  padding-right: 8px;
}
.rtl .onoffswitch2 .onoffswitch-inner:after {
  float: right;
  padding-left: 10px;
  text-align: left;
}
.rtl .onoffswitch2 .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  left: 0;
  right: auto;
}
.rtl .onoffswitch2 .onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: auto;
  margin-right: 0;
}
.rtl .onoffswitch2 .onoffswitch-switch {
  left: 45px;
  right: auto;
}
.rtl input[type=checkbox].switch-iphone:checked before {
  margin-left: 0;
  margin-right: 33px;
}
.rtl .DTTT_container.btn-group > .btn span i {
  margin-top: 2px;
}
.rtl .dataTables_filter {
  float: left;
}
.rtl .DTTT_container {
  float: left;
}
.rtl table.dataTable thead .sorting:before {
  float: left;
}
.rtl table.dataTable thead .sorting_asc:before {
  float: left;
}
.rtl .dataTable thead th {
  text-align: right;
}
.rtl .dataTable thead th input[type=text] {
  background-position: 95% 10px;
  padding-left: 12px;
  padding-right: 32px;
  float: right;
}
.rtl .dataTable tfoot th input[type=text] {
  background-position: 95% 10px;
  padding-left: 12px;
  padding-right: 32px;
  float: right;
}
.rtl .filter-right .dataTables_filter {
  float: left !important;
}
.rtl .form-inline label {
  margin-left: 10px;
  margin-right: 0;
}
.rtl .pagination li {
  float: right;
  margin-left: 0;
  margin-right: 5px;
}
.rtl .pagination2 .fa-angle-right:before {
  content: "Next";
}
.rtl .fa-angle-right:before {
  content: "\f104";
}
.rtl .select2-container .select2-choice abbr {
  left: 31px;
  right: auto;
}
.rtl .after-tooltip i {
  float: left;
  left: 0;
  right: auto;
}
.rtl .after-tooltip input {
  float: right;
}
.rtl .before-tooltip i {
  left: auto;
  right: -18px;
}
.rtl .inside-tooltip i {
  left: auto;
  right: 17px;
}
.rtl .inside-tooltip input {
  padding-left: 0;
  padding-right: 36px;
}
.rtl .inside-tooltip span {
  padding-left: 0;
  padding-right: 36px;
}
.rtl .panel-heading h4 > a:after {
  float: left;
}
.rtl .panel-heading h4 > a.collapsed:after {
  float: left;
}
.rtl .jstree-default .jstree-node {
  margin-left: 0;
  margin-right: 24px;
}
.rtl .dropdown-menu li a i {
  padding-left: 8px;
  padding-right: 0;
}
.rtl input,
.rtl p,
.rtl label,
.rtl div,
.rtl section,
.rtl textarea,
.rtl .form-horizontal .control-label {
  text-align: right;
}
.rtl .datepicker-dropdown.datepicker-orient-left:before {
  right: 6px;
  left: auto;
}
.rtl .datepicker-dropdown.datepicker-orient-left:after {
  right: 7px;
  left: auto;
}
.rtl .m-l-5 {
  margin-right: 5px !important;
  margin-left: 0 !important;
}
.rtl .m-l-10 {
  margin-right: 10px !important;
  margin-left: 0 !important;
}
.rtl .m-l-20 {
  margin-right: 20px !important;
  margin-left: 0 !important;
}
.rtl .m-l-30 {
  margin-right: 30px !important;
  margin-left: 0 !important;
}
.rtl .m-l-60 {
  margin-right: 60px !important;
  margin-left: 0 !important;
}
.rtl .m-r-5 {
  margin-left: 5px !important;
  margin-right: 0 !important;
}
.rtl .m-r-10 {
  margin-left: 10px !important;
  margin-right: 0 !important;
}
.rtl .m-r-20 {
  margin-left: 20px !important;
  margin-right: 0 !important;
}
.rtl .m-r-30 {
  margin-left: 30px !important;
  margin-right: 0 !important;
}
.rtl .m-r-60 {
  margin-left: 60px !important;
  margin-right: 0 !important;
}
.rtl .p-l-5 {
  padding-right: 5px !important;
  padding-left: 0 !important;
}
.rtl .p-l-10 {
  padding-right: 10px !important;
  padding-left: 0 !important;
}
.rtl .p-l-20 {
  padding-right: 20px !important;
  padding-left: 0 !important;
}
.rtl .p-l-30 {
  padding-right: 30px !important;
  padding-left: 0 !important;
}
.rtl .p-l-40 {
  padding-right: 40px !important;
  padding-left: 0 !important;
}
.rtl .p-r-5 {
  padding-left: 5px !important;
  padding-right: 0 !important;
}
.rtl .p-r-10 {
  padding-left: 10px !important;
  padding-right: 0 !important;
}
.rtl .p-r-20 {
  padding-left: 20px !important;
  padding-right: 0 !important;
}
.rtl .p-r-30 {
  padding-left: 30px !important;
  padding-right: 0 !important;
}
.rtl .l-5 {
  right: 5px;
  left: auto;
}
.rtl .l-10 {
  right: 10px;
  left: auto;
}
.rtl .l-15 {
  right: 15px;
  left: auto;
}
.rtl .r-0 {
  right: auto;
  left: 0;
}
.rtl .r-5 {
  left: 5px;
  right: auto;
}
.rtl .r-10 {
  left: 10px;
  right: auto;
}
.rtl .r-15 {
  left: 15px;
  right: auto;
}
.tabs-linetriangle .nav-tabs li.lines-3.active a::before {top: 61px;}
.tabs-linetriangle .nav-tabs li.lines-3.active a::after {z-index: 200;top:63px;}
.nav-tabs > li > a::before {z-index: 2;}
.tab-content .select2 {width: 100%!important;}