.tabs {
    display: flex;
    justify-content: space-between;
}

.tabs button {
    background-color: lightgray;
    border: none;
    color: #888888;
    cursor: pointer;
    padding: 25px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    transition: all 0.5s ease-out;
}

.tabs button:hover {
    color: white;
    background-color: rgba(255, 255, 255, 0.15);
}

.tabs button:disabled {
    background: linear-gradient(245.39deg, #fcd965 -35.14%, #ff0000 143.06%);
    color: white;
}
.autoselect {
    float:right;
}