/*!
 * Jasny Bootstrap v3.1.3 (http://jasny.github.io/bootstrap)
 * Copyright 2012-2014 Arnold Daniels
 * Licensed under Apache-2.0 (https://github.com/jasny/bootstrap/blob/master/LICENSE)
 */

.container-smooth {
  max-width: 1170px;
}
@media (min-width: 1px) {
  .container-smooth {
    width: auto;
  }
}
.btn-labeled {
  padding-top: 0;
  padding-bottom: 0;
}
.btn-label {
  position: relative;
  left: -12px;
  display: inline-block;
  padding: 6px 12px;
  background: transparent;
  background: rgba(0, 0, 0, .15);
  border-radius: 3px 0 0 3px;
}
.btn-label.btn-label-right {
  right: -12px;
  left: auto;
  border-radius: 0 3px 3px 0;
}
.btn-lg .btn-label {
  left: -16px;
  padding: 10px 16px;
  border-radius: 5px 0 0 5px;
}
.btn-lg .btn-label.btn-label-right {
  right: -16px;
  left: auto;
  border-radius: 0 5px 5px 0;
}
.btn-sm .btn-label {
  left: -10px;
  padding: 5px 10px;
  border-radius: 2px 0 0 2px;
}
.btn-sm .btn-label.btn-label-right {
  right: -10px;
  left: auto;
  border-radius: 0 2px 2px 0;
}
.btn-xs .btn-label {
  left: -5px;
  padding: 1px 5px;
  border-radius: 2px 0 0 2px;
}
.btn-xs .btn-label.btn-label-right {
  right: -5px;
  left: auto;
  border-radius: 0 2px 2px 0;
}
.nav-tabs-bottom {
  border-top: 1px solid #ddd;
  border-bottom: 0;
}
.nav-tabs-bottom > li {
  margin-top: -1px;
  margin-bottom: 0;
}
.nav-tabs-bottom > li > a {
  border-radius: 0 0 4px 4px;
}
.nav-tabs-bottom > li > a:hover,
.nav-tabs-bottom > li > a:focus,
.nav-tabs-bottom > li.active > a,
.nav-tabs-bottom > li.active > a:hover,
.nav-tabs-bottom > li.active > a:focus {
  border: 1px solid #ddd;
  border-top-color: transparent;
}
.nav-tabs-left {
  border-right: 1px solid #ddd;
  border-bottom: 0;
}
.nav-tabs-left > li {
  float: none;
  margin-right: -1px;
  margin-bottom: 0;
}
.nav-tabs-left > li > a {
  margin-right: 0;
  margin-bottom: 2px;
  border-radius: 4px 0 0 4px;
}
.nav-tabs-left > li > a:hover,
.nav-tabs-left > li > a:focus,
.nav-tabs-left > li.active > a,
.nav-tabs-left > li.active > a:hover,
.nav-tabs-left > li.active > a:focus {
  border: 1px solid #ddd;
  border-right-color: transparent;
}
.row > .nav-tabs-left {
  position: relative;
  z-index: 1;
  padding-right: 0;
  padding-left: 15px;
  margin-right: -1px;
}
.row > .nav-tabs-left + .tab-content {
  border-left: 1px solid #ddd;
}
.nav-tabs-right {
  border-bottom: 0;
  border-left: 1px solid #ddd;
}
.nav-tabs-right > li {
  float: none;
  margin-bottom: 0;
  margin-left: -1px;
}
.nav-tabs-right > li > a {
  margin-bottom: 2px;
  margin-left: 0;
  border-radius: 0 4px 4px 0;
}
.nav-tabs-right > li > a:hover,
.nav-tabs-right > li > a:focus,
.nav-tabs-right > li.active > a,
.nav-tabs-right > li.active > a:hover,
.nav-tabs-right > li.active > a:focus {
  border: 1px solid #ddd;
  border-left-color: transparent;
}
.row > .nav-tabs-right {
  padding-right: 15px;
  padding-left: 0;
}
.navmenu,
.navbar-offcanvas {
  width: 300px;
  height: auto;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
}
.navmenu-fixed-left,
.navmenu-fixed-right,
.navbar-offcanvas {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
  overflow-y: auto;
  border-radius: 0;
}
.navmenu-fixed-left,
.navbar-offcanvas.navmenu-fixed-left {
  right: auto;
  left: 0;
  border-width: 0 1px 0 0;
}
.navmenu-fixed-right,
.navbar-offcanvas {
  right: 0;
  left: auto;
  border-width: 0 0 0 1px;
}
.navmenu-nav {
  margin-bottom: 10px;
}
.navmenu-nav.dropdown-menu {
  position: static;
  float: none;
  padding-top: 0;
  margin: 0;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.navbar-offcanvas .navbar-nav {
  margin: 0;
}
@media (min-width: 768px) {
  .navbar-offcanvas {
    width: auto;
    border-top: 0;
    box-shadow: none;
  }
  .navbar-offcanvas.offcanvas {
    position: static;
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }
  .navbar-offcanvas .navbar-nav.navbar-left:first-child {
    margin-left: -15px;
  }
  .navbar-offcanvas .navbar-nav.navbar-right:last-child {
    margin-right: -15px;
  }
  .navbar-offcanvas .navmenu-brand {
    display: none;
  }
}
.navmenu-brand {
  display: block;
  padding: 10px 15px;
  margin: 10px 0;
  font-size: 18px;
  line-height: 20px;
}
.navmenu-brand:hover,
.navmenu-brand:focus {
  text-decoration: none;
}
.navmenu-default,
.navbar-default .navbar-offcanvas {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}
.navmenu-default .navmenu-brand,
.navbar-default .navbar-offcanvas .navmenu-brand {
  color: #777;
}
.navmenu-default .navmenu-brand:hover,
.navbar-default .navbar-offcanvas .navmenu-brand:hover,
.navmenu-default .navmenu-brand:focus,
.navbar-default .navbar-offcanvas .navmenu-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
.navmenu-default .navmenu-text,
.navbar-default .navbar-offcanvas .navmenu-text {
  color: #777;
}
.navmenu-default .navmenu-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a:hover .caret,
.navmenu-default .navmenu-nav > .dropdown > a:focus .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a:focus .caret {
  border-top-color: #333;
  border-bottom-color: #333;
}
.navmenu-default .navmenu-nav > .open > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a,
.navmenu-default .navmenu-nav > .open > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:hover,
.navmenu-default .navmenu-nav > .open > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav > .open > a .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a .caret,
.navmenu-default .navmenu-nav > .open > a:hover .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:hover .caret,
.navmenu-default .navmenu-nav > .open > a:focus .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .open > a:focus .caret {
  border-top-color: #555;
  border-bottom-color: #555;
}
.navmenu-default .navmenu-nav > .dropdown > a .caret,
.navbar-default .navbar-offcanvas .navmenu-nav > .dropdown > a .caret {
  border-top-color: #777;
  border-bottom-color: #777;
}
.navmenu-default .navmenu-nav.dropdown-menu,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu {
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav.dropdown-menu > .divider,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .divider {
  background-color: #f8f8f8;
}
.navmenu-default .navmenu-nav.dropdown-menu > .active > a,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a,
.navmenu-default .navmenu-nav.dropdown-menu > .active > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:hover,
.navmenu-default .navmenu-nav.dropdown-menu > .active > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:focus {
  background-color: #d7d7d7;
}
.navmenu-default .navmenu-nav > li > a,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a {
  color: #777;
}
.navmenu-default .navmenu-nav > li > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:hover,
.navmenu-default .navmenu-nav > li > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > li > a:focus {
  color: #333;
  background-color: transparent;
}
.navmenu-default .navmenu-nav > .active > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a,
.navmenu-default .navmenu-nav > .active > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:hover,
.navmenu-default .navmenu-nav > .active > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .active > a:focus {
  color: #555;
  background-color: #e7e7e7;
}
.navmenu-default .navmenu-nav > .disabled > a,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a,
.navmenu-default .navmenu-nav > .disabled > a:hover,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a:hover,
.navmenu-default .navmenu-nav > .disabled > a:focus,
.navbar-default .navbar-offcanvas .navmenu-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navmenu-inverse,
.navbar-inverse .navbar-offcanvas {
  background-color: #222;
  border-color: #080808;
}
.navmenu-inverse .navmenu-brand,
.navbar-inverse .navbar-offcanvas .navmenu-brand {
  color: #999;
}
.navmenu-inverse .navmenu-brand:hover,
.navbar-inverse .navbar-offcanvas .navmenu-brand:hover,
.navmenu-inverse .navmenu-brand:focus,
.navbar-inverse .navbar-offcanvas .navmenu-brand:focus {
  color: #fff;
  background-color: transparent;
}
.navmenu-inverse .navmenu-text,
.navbar-inverse .navbar-offcanvas .navmenu-text {
  color: #999;
}
.navmenu-inverse .navmenu-nav > .dropdown > a:hover .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a:hover .caret,
.navmenu-inverse .navmenu-nav > .dropdown > a:focus .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navmenu-inverse .navmenu-nav > .open > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a,
.navmenu-inverse .navmenu-nav > .open > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:hover,
.navmenu-inverse .navmenu-nav > .open > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:focus {
  color: #fff;
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav > .open > a .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a .caret,
.navmenu-inverse .navmenu-nav > .open > a:hover .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:hover .caret,
.navmenu-inverse .navmenu-nav > .open > a:focus .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .open > a:focus .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}
.navmenu-inverse .navmenu-nav > .dropdown > a .caret,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .dropdown > a .caret {
  border-top-color: #999;
  border-bottom-color: #999;
}
.navmenu-inverse .navmenu-nav.dropdown-menu,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu {
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav.dropdown-menu > .divider,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .divider {
  background-color: #222;
}
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a,
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:hover,
.navmenu-inverse .navmenu-nav.dropdown-menu > .active > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav.dropdown-menu > .active > a:focus {
  background-color: #000;
}
.navmenu-inverse .navmenu-nav > li > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a {
  color: #999;
}
.navmenu-inverse .navmenu-nav > li > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a:hover,
.navmenu-inverse .navmenu-nav > li > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > li > a:focus {
  color: #fff;
  background-color: transparent;
}
.navmenu-inverse .navmenu-nav > .active > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a,
.navmenu-inverse .navmenu-nav > .active > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a:hover,
.navmenu-inverse .navmenu-nav > .active > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .active > a:focus {
  color: #fff;
  background-color: #080808;
}
.navmenu-inverse .navmenu-nav > .disabled > a,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a,
.navmenu-inverse .navmenu-nav > .disabled > a:hover,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a:hover,
.navmenu-inverse .navmenu-nav > .disabled > a:focus,
.navbar-inverse .navbar-offcanvas .navmenu-nav > .disabled > a:focus {
  color: #444;
  background-color: transparent;
}
.alert-fixed-top,
.alert-fixed-bottom {
  position: fixed;
  left: 0;
  z-index: 1035;
  width: 100%;
  margin: 0;
  border-radius: 0;
}
@media (min-width: 992px) {
  .alert-fixed-top,
  .alert-fixed-bottom {
    left: 50%;
    width: 992px;
    margin-left: -496px;
  }
}
.alert-fixed-top {
  top: 0;
  border-width: 0 0 1px 0;
}
@media (min-width: 992px) {
  .alert-fixed-top {
    border-width: 0 1px 1px 1px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}
.alert-fixed-bottom {
  bottom: 0;
  border-width: 1px 0 0 0;
}
@media (min-width: 992px) {
  .alert-fixed-bottom {
    border-width: 1px 1px 0 1px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
.offcanvas {
  display: none;
}
.offcanvas.in {
  display: block;
}
@media (max-width: 767px) {
  .offcanvas-xs {
    display: none;
  }
  .offcanvas-xs.in {
    display: block;
  }
}
@media (max-width: 991px) {
  .offcanvas-sm {
    display: none;
  }
  .offcanvas-sm.in {
    display: block;
  }
}
@media (max-width: 1199px) {
  .offcanvas-md {
    display: none;
  }
  .offcanvas-md.in {
    display: block;
  }
}
.offcanvas-lg {
  display: none;
}
.offcanvas-lg.in {
  display: block;
}
.canvas-sliding {
  -webkit-transition: top .35s, left .35s, bottom .35s, right .35s;
          transition: top .35s, left .35s, bottom .35s, right .35s;
}
.offcanvas-clone {
  position: absolute !important;
  top: auto !important;
  right: 0 !important;
  bottom: 0 !important;
  left: auto !important;
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden !important;
  border: none !important;
  opacity: 0 !important;
}
.table.rowlink td:not(.rowlink-skip),
.table .rowlink td:not(.rowlink-skip) {
  cursor: pointer;
}
.table.rowlink td:not(.rowlink-skip) a,
.table .rowlink td:not(.rowlink-skip) a {
  font: inherit;
  color: inherit;
  text-decoration: inherit;
}
.table-hover.rowlink tr:hover td,
.table-hover .rowlink tr:hover td {
  background-color: #cfcfcf;
}
.btn-file {
  position: relative;
  overflow: hidden;
  vertical-align: middle;
}
.btn-file > input {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 23px;
  cursor: pointer;
  filter: alpha(opacity=0);
  opacity: 0;

  direction: ltr;
}
.fileinput {
  display: inline-block;
  margin-bottom: 9px;
  width: 100%;
}
.fileinput .thumbnail {
  display: inline-block;
  margin-bottom: 5px;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
}
.fileinput .thumbnail > img {
  max-height: 100%;
}
.fileinput .btn {
  vertical-align: middle;
}
.fileinput-exists .fileinput-new,
.fileinput-new .fileinput-exists {
  display: none;
}
.fileinput-inline .fileinput-controls {
  display: inline;
}
.fileinput-filename {
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
}
.form-control .fileinput-filename {
  vertical-align: bottom;
}
.fileinput.input-group {
  display: table;
}
.fileinput.input-group > * {
  position: relative;
  z-index: 2;
}
.fileinput.input-group > .btn-file {
  z-index: 1;
}
.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 4px 4px 0;
}
.fileinput-new.input-group .btn-file.btn-xs,
.fileinput-new .input-group .btn-file.btn-xs,
.fileinput-new.input-group .btn-file.btn-sm,
.fileinput-new .input-group .btn-file.btn-sm {
  border-radius: 0 3px 3px 0;
}
.fileinput-new.input-group .btn-file.btn-lg,
.fileinput-new .input-group .btn-file.btn-lg {
  border-radius: 0 6px 6px 0;
}
.form-group.has-warning .fileinput .fileinput-preview {
  color: #8a6d3b;
}
.form-group.has-warning .fileinput .thumbnail {
  border-color: #faebcc;
}
.form-group.has-error .fileinput .fileinput-preview {
  color: #a94442;
}
.form-group.has-error .fileinput .thumbnail {
  border-color: #ebccd1;
}
.form-group.has-success .fileinput .fileinput-preview {
  color: #3c763d;
}
.form-group.has-success .fileinput .thumbnail {
  border-color: #d6e9c6;
}
.input-group-addon:not(:first-child) {
  border-left: 0;
}
/*# sourceMappingURL=jasny-bootstrap.css.map */
