/* Pretty printing styles. Used with prettify.js.
 *
 * Name:  Stanley Ng
 * Email:   stanleyhlng@googlegroups.com
 * 
 * Credits:
 * https://github.com/chriskempson/tomorrow-theme
 */
.pln {
  color: #c5c8c6; }

.str {
  color: #b5bd68; }

.kwd {
  color: #b294bb; }

.com {
  color: #969896;
  font-style: italic; }

.typ {
  color: #de935f; }

.lit {
  color: #f0c674; }

.pun {
  color: #c5c8c6; }

.opn {
  color: #c5c8c6; }

.clo {
  color: #c5c8c6; }

.tag {
  color: #cc6666; }

.atn {
  color: #f0c674; }

.atv {
  color: #8abeb7; }

.dec {
  color: #c5c8c6; }

.var {
  color: #c82829; }

.fun {
  color: #cc6666; }

/* Put a border around prettyprinted code snippets. */
pre.prettyprint {
  background-color: #1d1f21;
  padding: 10px;
  border: 1px solid #E1E1E8; }

/* Specify class=linenums on a pre to get line numbering */
ol.linenums {
  color: #969896;
  margin: 0;
  padding: 0 0 0 35px !important; }

ol.linenums li {
  line-height: 18px;
  padding-left: 0; }